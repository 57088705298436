import React from "react";
import Menu from "../components/Menu";
import Create from "../components/estimates/Create";
import { CREATE } from "../components/menuItemTypes";

const estimateView = () => {
  return (
    <>
      <Menu contentView={<Create />} renderMenuFor={CREATE} />
    </>
  );
};

export default estimateView;
