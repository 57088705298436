import React from "react";
import { Dropdown, Popup } from "semantic-ui-react";

const ActionsMenu = ({ onDropdownSelect, actions, icon, className }) => {
  const handleChange = (event, data) => {
    if (data) {
      onDropdownSelect(data.value);
    }
  };

  const dropdownStyle = {
    marginBottom: "20px",
    float: "right",
  };

  const menuStyle = {
    borderStyle: "none",
    boxShadow: "none",
  };

  return (
    <>
      <Popup
        trigger={
          <Dropdown
            icon={icon}
            pointing="top right"
            className={className}
            style={dropdownStyle}
          >
            <Dropdown.Menu>
              <Dropdown.Menu style={menuStyle} scrolling>
                {actions.map((action) => (
                  <Dropdown.Item
                    key={action.value}
                    {...action}
                    onClick={handleChange}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown>
        }
        content="Actions"
        position="left center"
      />
    </>
  );
};

export default ActionsMenu;
