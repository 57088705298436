import React from "react";
import { cancelEstimateSave, saveEstimate } from "../../actions";
import { connect } from "react-redux";
import SaveModal from "./SaveModal";
import { v4 as uuidv4 } from "uuid";
import history from "../../history";
import { compileDataForSave } from "../../apis/Estimates";

class newEstimateSave extends React.Component {
  executeSave = (saveAsName, insurer, registration) => {
    //get an ID for the estimate
    const estimateId = uuidv4();

    const data = compileDataForSave(
      estimateId,
      saveAsName,
      insurer,
      registration,
      this.props.newEstimateItems,
      this.props.workUnitsPerHour,
      this.props.username,
      this.props.userId
    );

    this.props.saveEstimate(data);
  };

  cancelSave = () => {
    this.props.cancelEstimateSave();
    history.push("/dashboard");
  };

  render() {
    return (
      <SaveModal
        title="Save Quote"
        onSubmitClick={this.executeSave}
        onCancelClick={this.cancelSave}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newEstimateItems: state.newEstimateItems,
    userId: state.auth.userId,
    username: state.auth.email,
    workUnitsPerHour: state.user.settings.setitems.labourUnitsPerHour,
  };
};

export default connect(mapStateToProps, {
  cancelEstimateSave,
  saveEstimate,
})(newEstimateSave);
