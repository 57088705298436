import React from "react";
import Menu from "../components/Menu";
import Dashboard from "../components/estimates/Dashboard";
import { ESTIMATES } from "../components/menuItemTypes";

const dashboardView = () => {
  return (
    <>
      <Menu contentView={<Dashboard />} renderMenuFor={ESTIMATES} />
    </>
  );
};

export default dashboardView;
