import React from "react";
import "../components.css";

const EstimateItemDetail = ({ estimateItem, itemNumber }) => {
  const renderMeasurements = () => {
    if (estimateItem.area) {
      return (
        <div
          data-tooltip="Sealer Area"
          style={{ paddingTop: "1%" }}
          className="description"
        >
          <span>
            <i className="large expand arrows alternate icon"></i>
          </span>
          <span style={{ paddingLeft: "1%" }}>
            {estimateItem.area.toFixed(2)}
          </span>
        </div>
      );
    } else if (estimateItem.length) {
      return (
        <div
          data-tooltip="Sealer Length"
          style={{ paddingTop: "1%" }}
          className="description"
        >
          <span>
            <i className="large arrows alternate horizontal icon"></i>
          </span>
          <span style={{ paddingLeft: "1%" }}>{estimateItem.length}</span>
        </div>
      );
    } else {
      //panels
      return (
        <div
          data-tooltip="Number of panels"
          style={{ paddingTop: "1%" }}
          className="description"
        >
          <span>
            <i className="large list ol icon"></i>
          </span>
          <span style={{ paddingLeft: "1%" }}>{estimateItem.panels}</span>
        </div>
      );
    }
  };

  const renderTotal = () => {
    if (estimateItem.itemcost) {
      return (
        <div
          data-tooltip="Item Total Cost"
          style={{ paddingTop: "1%" }}
          className="description"
        >
          <span>
            <i className="large money bill alternate icon"></i>
          </span>
          <span style={{ paddingLeft: "1%" }}>
            {estimateItem.itemcost.toFixed(2)}
          </span>
        </div>
      );
    }
  };

  const renderMaterials = () => {
    if (estimateItem.totalmaterial) {
      return (
        <div
          data-tooltip="Material Cost"
          style={{ paddingTop: "1%" }}
          className="description"
        >
          <span>
            <i className="large paint brush icon"></i>
          </span>
          <span style={{ paddingLeft: "1%" }}>
            {estimateItem.totalmaterial.toFixed(2)}
          </span>
        </div>
      );
    }
  };

  const renderLabour = () => {
    let labourCostTime = "";
    let tipText = "";
    if (estimateItem.totallabourunits) {
      labourCostTime = estimateItem.totallabourunits;
      tipText = "Labour Units";
    } else {
      labourCostTime = estimateItem.totallabourcost;
      tipText = "Labour Cost";
    }
    return (
      <div
        data-tooltip={tipText}
        style={{ paddingTop: "1%" }}
        className="description"
      >
        <span>
          <i className="large stopwatch icon"></i>
        </span>
        <span style={{ paddingLeft: "1%" }}>{labourCostTime.toFixed(2)}</span>
      </div>
    );
  };

  return (
    <>
      <div className="ui stackable three column grid">
        <div className="seven wide column" key={estimateItem.recordId}>
          <span
            style={{ fontSize: "1.5em", color: "blue", fontWeight: "bold" }}
          >
            {itemNumber}
          </span>
          <span
            style={{ fontWeight: "bold", paddingLeft: "1em" }}
            className="header"
          >
            {estimateItem.sealertype}
          </span>
        </div>
        <div className="two wide column">{renderMeasurements()}</div>
        <div className="two wide column">{renderMaterials()}</div>
        <div className="three wide column">{renderLabour()}</div>
        <div className="two wide column">{renderTotal()}</div>
      </div>
      <div className="ui divider"></div>
    </>
  );
};

export default EstimateItemDetail;
