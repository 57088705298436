import React from "react";
import UpdateModal from "./UpdateItemModal";
import {
  getEstimate,
  selectEstimateItem,
  getSealer,
  updateEstimate,
} from "../../actions";
import { connect } from "react-redux";
import _ from "lodash";

class UpdateEstimateItem extends React.Component {
  state = { dataLoaded: false };

  componentDidMount() {
    this.setState({ dataLoaded: false });
    this.getData().then(this.setState({ dataLoaded: true }));
  }

  getData = async () => {
    await this.props
      .getEstimate(this.props.match.params.qid)
      .then(async () => {
        await this.props.selectEstimateItem(
          this.props.estimateItems[this.props.match.params.iid]
        );
      })
      .then(async () => {
        this.props.getSealer(this.props.selectedItem.sealerid);
      });
  };

  render() {
    if (this.state.dataLoaded) {
      return (
        <UpdateModal
          initialValues={_.pick(
            this.props.selectedItem,
            "sealerlength",
            "sealerwidth",
            "panels",
            "shade"
          )}
        />
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    estimate: state.estimates.estimate,
    estimateItems: state.newEstimateItems,
    selectedItem: state.estimates.selectedItem,
    selectedSealer: state.selectedSealer,
    workUnitsPerHour: state.user.settings.setitems.labourUnitsPerHour,
    userName: state.auth.email,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps, {
  getEstimate,
  selectEstimateItem,
  getSealer,
  updateEstimate,
})(UpdateEstimateItem);
