import React from "react";
//use Router instead of BrowserRouter to get access to history object for programmatic navigation etc.
import { Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { signIn } from "../actions";
import estimateView from "../views/estimateView";
import dashboardView from "../views/dashboardView";
import estimateItemsView from "../views/estimateItemsView";
import estimateUpdateView from "../views/estimateUpdateView";
import UpdateEstimateItem from "./estimates/UpdateEstimateItem";
import updateProfileView from "../views/updateProfileView";
import ResetPassword from "./authentication/ResetPassword";
import ConfirmEmail from "./authentication/ConfirmEmail";
import Archive from "./estimates/Archive";
import DeleteEstimateItem from "./estimates/DeleteEstimateItem";
import EmailEstimate from "./estimates/EmailEstimate";
import history from "../history";
import SignIn from "./authentication/SignIn";
import SignUp from "./authentication/SignUp";
import "fomantic-ui-css/semantic.css";
import "./components.css";
import NewEstimateSave from "../components/estimates/NewEstimateSave";
import { env } from "../apis/env";
import privacy from "../privacy";

class App extends React.Component {
  _env = env();

  componentDidMount() {
    document.body.style.backgroundColor = "#f4f6f8";
  }

  renderFooter() {
    const versionNumber = `Version ${this._env.VERSION}`;
    return (
      <div
        className="ui tiny green segment"
        style={{ fontWeight: "bold", marginTop: "2em" }}
      >
        <div className="ui stackable two column grid">
          <div className="eight wide column">{versionNumber}</div>
          <div className="eight wide column" style={{ textAlign: "right" }}>
            Designed & Developed by{" "}
            <a
              href="https://leadrocketdigital.com"
              target="_blank"
              rel="noreferrer"
            >
              Lead Rocket Digital
            </a>{" "}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="ui container margin-top-medium margin-bottom-wide">
        <Router history={history}>
          <Route path="/dashboard" component={dashboardView} />
          <Route path="/estimates/:id" component={estimateItemsView} />
          <Route path="/profile" component={updateProfileView} />
          <Route path="/create" component={estimateView} />
          <Route path="/" exact component={SignIn} />
          <Route path="/save" component={NewEstimateSave} />
          <Route
            path="/updateitem/:qid/:iid"
            exact
            component={UpdateEstimateItem}
          />
          <Route path="/signup" exact component={SignUp} />
          <Route path="/reset" exact component={ResetPassword} />
          <Route path="/activate" exact component={ConfirmEmail} />
          <Route path="/archive/:id" exact component={Archive} />
          <Route
            path="/deleteitem/:qid/:iid"
            exact
            component={DeleteEstimateItem}
          />
          <Route path="/share/:id" exact component={EmailEstimate} />
          <Route path="/update/:id" exact component={estimateUpdateView} />
          <Route path="/privacy" exact component={privacy} />
        </Router>
        {this.renderFooter()}
        <div className="spacer-small"></div>
      </div>
    );
  }
}

export default connect(null, { signIn: signIn })(App);
