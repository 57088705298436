  import React from "react";
  import Menu from "../components/Menu";
  import EstimateItemList from "../components/estimates/EstimateItemList";
  import { ESTIMATE_ITEMS } from "../components/menuItemTypes";

  const estimateItemsView = (props) => {
    return (
      <>
        <Menu
          contentView={<EstimateItemList estimateId={props.match.params.id} />}
          renderMenuFor={ESTIMATE_ITEMS}
        />
      </>
    );
  };

  export default estimateItemsView;
