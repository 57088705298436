import {
  BUSY_SAVING,
  SAVED_RECORD,
  SAVE_FAILED,
  CANCEL_SAVE,
  SAVED_CALC_METHOD,
  BUSY_SAVING_CALC_METHOD,
  SAVE_FAILED_CALC_METHOD,
  CANCEL_SAVE_CALC_METHOD,
  SAVED_CURRENCY,
  BUSY_SAVING_CURRENCY,
  SAVE_FAILED_CURRENCY,
  CANCEL_SAVE_CURRENCY,
  BUSY_SAVING_LABOUR_UNITS_PER_HOUR,
  SAVED_LABOUR_UNITS_PER_HOUR,
  CANCEL_SAVE_LABOUR_UNITS_PER_HOUR,
  BUSY_MAILING,
  MAIL_FAILED,
  MAILED_OK,
  CANCEL_MAIL_ESTIMATE,
} from "../actions/types";

const INITIAL_STATE = {
  saveSuccess: null,
  busySaving: null,
  saveError: null,
  successMessage: null,
  busyMailingEstimate: null,
  mailEstimateSuccess: null,
  mailErrorMessage: null,
  mailSuccessMessage: null,
  busySavingCalcMethod: null,
  saveSuccessCalcMethod: null,
  saveErrorCalcMethod: null,
  successMessageCalcMethod: null,
  busySavingLabourUnits: null,
  saveSuccessLabourUnits: null,
  saveErrorLabourUnits: null,
  successMessageLabourUnits: null,
  busySavingCurrency: null,
  saveSuccessCurrency: null,
  saveErrorCurrency: null,
  successMessageCurrency: null,
};

const ApiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVED_RECORD:
      return {
        ...state,
        busySaving: false,
        saveSuccess: true,
        successMessage: action.payload,
      };
    case BUSY_SAVING:
      return { ...state, busySaving: true, saveSuccess: false };
    case SAVE_FAILED:
      return {
        ...state,
        busySaving: false,
        saveSuccess: false,
        saveError: action.payload,
        successMessage: null,
      };
    case CANCEL_SAVE:
      return {
        ...state,
        busySaving: false,
        saveSuccess: false,
        saveError: null,
        successMessage: null,
      };
    case SAVED_CALC_METHOD:
      return {
        ...state,
        busySavingCalcMethod: false,
        saveSuccessCalcMethod: true,
        successMessageCalcMethod: action.payload,
      };
    case BUSY_SAVING_CALC_METHOD:
      return {
        ...state,
        busySavingCalcMethod: true,
        saveSuccessCalcMethod: false,
      };
    case SAVE_FAILED_CALC_METHOD:
      return {
        ...state,
        busySavingCalcMethod: false,
        saveSuccessCalcMethod: false,
        saveErrorCalcMethod: action.payload,
        successMessageCalcMethod: null,
      };
    case CANCEL_SAVE_CALC_METHOD:
      return {
        ...state,
        busySavingCalcMethod: false,
        saveSuccessCalcMethod: false,
        saveErrorCalcMethod: null,
        successMessageCalcMethod: null,
      };
    case SAVED_LABOUR_UNITS_PER_HOUR:
      return {
        ...state,
        busySavingLabourUnits: false,
        saveSuccessLabourUnits: true,
        successMessageLabourUnits: action.payload,
      };
    case BUSY_SAVING_LABOUR_UNITS_PER_HOUR:
      return {
        ...state,
        busySavingLabourUnits: true,
        saveSuccessLabourUnits: false,
      };
    case CANCEL_SAVE_LABOUR_UNITS_PER_HOUR:
      return {
        ...state,
        busySavingLabourUnits: false,
        saveSuccessLabourUnits: false,
        saveErrorLabourUnits: null,
        successMessageLabourUnits: null,
      };
    case SAVED_CURRENCY:
      return {
        ...state,
        busySavingCurrency: false,
        saveSuccessCurrency: true,
        successMessageCurrency: action.payload,
      };
    case BUSY_SAVING_CURRENCY:
      return {
        ...state,
        busySavingCurrency: true,
        saveSuccessCurrency: false,
      };
    case SAVE_FAILED_CURRENCY:
      return {
        ...state,
        busySavingCurrency: false,
        saveSuccessCurrency: false,
        saveErrorCurrency: action.payload,
        successMessageCurrency: null,
      };
    case CANCEL_SAVE_CURRENCY:
      return {
        ...state,
        busySavingCurrency: false,
        saveSuccessCurrency: false,
        saveErrorCurrency: null,
        successMessageCurrency: null,
      };
    case BUSY_MAILING:
      return {
        ...state,
        busyMailingEstimate: true,
        mailEstimateSuccess: false,
        mailErrorMessage: null,
        mailSuccessMessage: null,
      };
    case MAILED_OK:
      return {
        ...state,
        busyMailingEstimate: false,
        mailEstimateSuccess: true,
        mailErrorMessage: null,
        mailSuccessMessage: action.payload,
      };
    case MAIL_FAILED:
      return {
        ...state,
        busyMailingEstimate: false,
        mailEstimateSuccess: false,
        mailErrorMessage: action.payload,
        mailSuccessMessage: null,
      };
    case CANCEL_MAIL_ESTIMATE:
      return {
        ...state,
        busyMailingEstimate: false,
        mailEstimateSuccess: false,
        mailErrorMessage: null,
        mailSuccessMessage: null,
      };
    default:
      return state;
  }
};

export default ApiReducer;
