import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Input, Button, Icon, Form, Grid, Checkbox } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { resetEstimateEmail } from "../../actions";

class EmailEstimateModal extends React.Component {
  state = { ccUser: false };

  renderInput = ({ input, type, meta, placeholder, id, autoFocus, value }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <Input
          type={type}
          fluid
          {...input}
          id={id}
          placeholder={placeholder}
          autoFocus={autoFocus}
          value={value}
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderCheckBox = ({ meta, id, label }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <Checkbox
          id={id}
          label={label}
          onChange={this.handleCCValueChange}
        ></Checkbox>
        {this.renderError(meta, id)}
      </div>
    );
  };

  handleCCValueChange = (e, props) => {
    this.setState({ ccUser: props.checked });
  };

  renderError = ({ error, submitFailed }) => {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  };

  emailEstimate = (formValues) => {
    let emailAddresses = formValues.addresses;
    if (this.state.ccUser) {
      emailAddresses += `, ${this.props.email}`;
    }
    this.props.onSendClick(emailAddresses);
  };

  renderResult = () => {
    if (this.props.mailError) {
      return <div className="ui error message">{this.props.mailError}</div>;
    } else if (this.props.mailSuccess) {
      return (
        <div className="ui success message">{this.props.mailSuccess}.</div>
      );
    }
  };

  handleChange = () => {
    this.props.resetEstimateEmail();
  };

  renderCancelText = () => {
    if (this.props.mailSuccess) {
      return "To Dashboard";
    } else {
      return "Cancel";
    }
  };

  render() {
    return ReactDOM.createPortal(
      <div className="ui dimmer modals visible active">
        <div className="ui standard modal visible active">
          <div className="header">{this.props.title}</div>
          <Grid stackable padded>
            <Grid.Column>
              <Form
                onSubmit={this.props.handleSubmit(
                  this.emailEstimate.bind(this)
                )}
              >
                <Form.Field>
                  <Field
                    name="addresses"
                    type="text"
                    id="emailAddresses"
                    placeholder="Email To (For multiple separate addresses with commas)"
                    component={this.renderInput}
                    onChange={this.handleChange}
                    autoFocus
                  ></Field>
                </Form.Field>
                <Form.Field>
                  <Field
                    name="ccMe"
                    type="checkbox"
                    component={this.renderCheckBox}
                    label="CC me"
                    toggle
                  ></Field>
                </Form.Field>

                <Button.Group className="ui right floated">
                  <Button
                    loading={this.props.busyMailing}
                    primary
                    icon
                    labelPosition="right"
                  >
                    Send
                    <Icon name="send"></Icon>
                  </Button>
                  <Button.Or />
                  <Button type="button" onClick={this.props.onCancelClick}>
                    {this.renderCancelText()}
                  </Button>
                </Button.Group>
              </Form>
            </Grid.Column>
          </Grid>
          <div className="content">{this.renderResult()}</div>
        </div>
      </div>,
      document.querySelector("#modal")
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  const emailRegex = /\S+@\S+\.\S+/;
  if (!emailRegex.test(formValues.addresses)) {
    //keys of object must match field names
    errors.addresses =
      "Please enter a valid email address or list of comma-separated email addresses";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "EmailEstimateModal",
  validate: validate,
})(EmailEstimateModal);

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    busyMailing: state.api.busyMailingEstimate,
    mailSuccess: state.api.mailSuccessMessage,
    mailError: state.api.mailErrorMessage,
  };
};

export default connect(mapStateToProps, { resetEstimateEmail })(formWrapped);
