import Sealers from "../apis/Sealers";
import { Estimates } from "../apis/Estimates";
import UserSettings from "../apis/UserSettings";
import {
  GET_SEALERS,
  CREATE_ESTIMATE_ITEM,
  LOAD_ESTIMATE_ITEM_FOR_EDIT,
  DELETE_ESTIMATE_ITEM,
  SELECT_SEALER,
  SHOW_SEALER_NOT_SELECTED_ERROR,
  HIDE_SEALER_NOT_SELECTED_ERROR,
  SIGN_IN,
  SIGN_OUT,
  SIGNED_UP,
  SIGN_UP,
  FAIL_SIGNUP,
  FAIL_SIGN_IN,
  BUSY_SAVING,
  SAVE_FAILED,
  SAVED_RECORD,
  CANCEL_SAVE,
  GET_ESTIMATES,
  SELECT_ESTIMATE,
  SELECT_ESTIMATE_ITEM,
  SET_USER_FIRSTNAME,
  CLEAR_NEW_ESTIMATE_ITEMS,
  SET_ESTIMATE_SEARCH_RANGE,
  SET_LAST_ESTIMATE_KEY,
  CLEAR_ESTIMATE,
  PASSWORD_RESET_REQUEST_SUCCESS,
  SET_RESET_USERNAME,
  RESET_SIGN_IN,
  GET_ESTIMATE,
  ARCHIVE_ESTIMATE,
  BUSY_MAILING,
  MAILED_OK,
  MAIL_FAILED,
  CANCEL_MAIL_ESTIMATE,
  GET_USER_SETTINGS,
  CREATE_USER_SETTINGS,
  SAVED_CALC_METHOD,
  BUSY_SAVING_CALC_METHOD,
  SAVE_FAILED_CALC_METHOD,
  CANCEL_SAVE_CALC_METHOD,
  SAVED_LABOUR_UNITS_PER_HOUR,
  SAVE_FAILED_LABOUR_UNITS_PER_HOUR,
  BUSY_SAVING_LABOUR_UNITS_PER_HOUR,
  CANCEL_SAVE_LABOUR_UNITS_PER_HOUR,
  SAVED_CURRENCY,
  BUSY_SAVING_CURRENCY,
  SAVE_FAILED_CURRENCY,
  CANCEL_SAVE_CURRENCY,
  UPDATE_ESTIMATE_ITEM,
  SET_SELECTED_SHADE,
  CLEAR_SELECTED_SHADE,
} from "../actions/types";
import SealerCost from "../apis/SealerCost";
import { profileSettingTypes } from "../components/userProfile/profileSettingTypes";
import { v4 as uuidv4 } from "uuid";
import history from "../history";

export const saveSuccess = (message) => {
  return { type: SAVED_RECORD, payload: message };
};

export const busySaving = () => {
  return { type: BUSY_SAVING };
};

export const saveFailed = (err) => {
  return { type: SAVE_FAILED, payload: err };
};

export const resetSaveSuccess = () => {
  return { type: CANCEL_SAVE };
};

export const saveCalcMethodSuccess = (message) => {
  return { type: SAVED_CALC_METHOD, payload: message };
};

export const busySavingCalcMethod = () => {
  return { type: BUSY_SAVING_CALC_METHOD };
};

export const saveFailedCalcMethod = (err) => {
  return { type: SAVE_FAILED_CALC_METHOD, payload: err };
};

export const resetSaveSuccessCalcMethod = () => {
  return { type: CANCEL_SAVE_CALC_METHOD };
};

export const saveLabourUnitsPerHourSuccess = () => {
  return { type: SAVED_LABOUR_UNITS_PER_HOUR };
};

export const busySavingLabourUnits = () => {
  return { type: BUSY_SAVING_LABOUR_UNITS_PER_HOUR };
};

export const saveFailedLabourUnitsPerHour = () => {
  return { type: SAVE_FAILED_LABOUR_UNITS_PER_HOUR };
};

export const resetSaveSuccessLabourUnitsPerHour = () => {
  return { type: CANCEL_SAVE_LABOUR_UNITS_PER_HOUR };
};

export const busySavingCurrency = () => {
  return { type: BUSY_SAVING_CURRENCY };
};

export const saveCurrencySuccess = () => {
  return { type: SAVED_CURRENCY };
};
export const saveFailedCurrency = (err) => {
  return { type: SAVE_FAILED_CURRENCY, payload: err };
};

export const resetSaveSuccessCurrency = () => {
  return { type: CANCEL_SAVE_CURRENCY };
};

export const signIn = (userId, email, authorizor, user) => {
  return {
    type: SIGN_IN,
    payload: {
      userId: userId,
      email: email,
      authorizor: authorizor,
      user: user,
    },
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};

export const setUserFirstName = (firstName) => {
  return {
    type: SET_USER_FIRSTNAME,
    payload: firstName,
  };
};

export const failSignIn = (err) => {
  return { type: FAIL_SIGN_IN, payload: err };
};

export const resetSignIn = () => {
  return { type: RESET_SIGN_IN };
};

export const signUp = () => {
  return {
    type: SIGN_UP,
  };
};

export const signedUp = (email) => {
  return { type: SIGNED_UP, payload: email };
};

export const failSignup = (err) => {
  return { type: FAIL_SIGNUP, payload: err };
};

export const getEstimates = (params) => {
  return async (dispatch) => {
    const response = await Estimates(params).get();
    dispatch({ type: GET_ESTIMATES, payload: response.data.data });
    dispatch({ type: SET_LAST_ESTIMATE_KEY, payload: response.data.lastKey });
  };
};

export const getEstimate = (estimateId) => {
  return async (dispatch) => {
    const response = await Estimates({ estimateId: estimateId }).get();
    dispatch({ type: GET_ESTIMATE, payload: response.data });
  };
};

export const loadEstimateForEdit = (estimateId) => {
  return async (dispatch) => {
    const response = await Estimates({ estimateId: estimateId }).get();
    dispatch({ type: GET_ESTIMATE, payload: response.data });
    response.data.items.forEach((item) => {
      const estimate = {
        recordId: item.id,
        totalMaterial: item.totalmaterial,
        totalLabourCost: item.totallabourcost,
        totalLabourUnits: item.totallabourunits,
        totalLabourMins: item.totallabourmins,
        total: item.itemcost,
        sealerlength: item.length,
        sealerwidth: item.width,
        area: item.area,
        panels: item.panels,
        name: item.sealertype,
        sealerid: item.sealerid,
        shade: item.shade,
      };
      dispatch({ type: LOAD_ESTIMATE_ITEM_FOR_EDIT, payload: estimate });
    });
  };
};

export const clearEstimate = () => {
  return { type: CLEAR_ESTIMATE };
};

export const saveEstimate = (estimate) => {
  return async (dispatch) => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Estimates({}).post("", estimate);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({ type: SAVED_RECORD });
  };
};

export const archiveEstimate = (estimateId) => {
  return async (dispatch) => {
    const response = await Estimates({}).patch(`archive/${estimateId}`);
    dispatch({ type: ARCHIVE_ESTIMATE, payload: response.data });
    history.push("/dashboard");
  };
};

export const updateEstimate = (estimate, id) => {
  return async (dispatch) => {
    console.log(estimate);
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Estimates({}).patch(id, estimate);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({ type: SAVED_RECORD });
  };
};

export const emailEstimate = (id, to) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await Estimates({}).post("/email", { id: id, to: to });
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: MAIL_FAILED, payload: e.message });
      }
    }
    dispatch({
      type: MAILED_OK,
      payload: "Successfully passed to mail server",
    });
  };
};

export const busyMailingEstimate = () => {
  return { type: BUSY_MAILING };
};

export const estimateSuccessfullyMailed = (message) => {
  return { type: MAILED_OK, payload: message };
};

export const estimateMailFailed = (error) => {
  return { type: MAIL_FAILED, payload: error };
};

export const resetEstimateEmail = () => {
  return { type: CANCEL_MAIL_ESTIMATE };
};

const estimateItemAdditionFailure = {
  cause: "NO_SEALER_SELECTED",
  message: "Please select a sealer type.",
};

export const updateEstimateItem = (
  { sealerlength, sealerwidth, labourrate },
  id,
  name,
  panels,
  shade,
  recordId
) => {
  let params = {};
  let area = 0.0;
  if (sealerwidth) {
    //calculate are in dm2 (input in mm)
    area = (sealerlength * sealerwidth) / 10000;
    params = {
      id: id,
      area: area,
      rate: labourrate,
      shade: shade,
    };
  } else if (sealerlength) {
    params = {
      id: id,
      length: sealerlength,
      rate: labourrate,
      shade: shade,
    };
  } else {
    params = {
      id: id,
      rate: labourrate,
      panels: panels,
    };
  }
  return async (dispatch) => {
    const response = await SealerCost(params).get();
    const estimate = {
      recordId: recordId,
      totalMaterial: response.data.totalMaterial,
      totalLabourCost: response.data.totalLabourCost,
      totalLabourMins: response.data.totalLabourMins,
      total: response.data.totalMaterial + response.data.totalLabourCost,
      name: name,
      sealerlength: sealerlength,
      sealerwidth: sealerwidth,
      sealerid: id,
      area: area,
      panels: panels,
      shade: shade,
    };
    console.log("Updated Dispatched Estimate", estimate);
    dispatch({ type: UPDATE_ESTIMATE_ITEM, payload: estimate });
  };
};

export const createEstimateItem = (
  { sealerlength, sealerwidth, labourrate },
  id,
  name,
  panels,
  shade
) => {
  if (!id) {
    return {
      type: SHOW_SEALER_NOT_SELECTED_ERROR,
      payload: estimateItemAdditionFailure,
    };
  }

  let params = {};
  let area = 0.0;
  if (sealerwidth) {
    //calculate are in dm2 (input in mm)
    area = (sealerlength * sealerwidth) / 10000;
    params = {
      id: id,
      area: area,
      rate: labourrate,
      shade: shade,
    };
  } else if (sealerlength) {
    params = {
      id: id,
      length: sealerlength,
      rate: labourrate,
      shade: shade,
    };
  } else {
    params = {
      id: id,
      rate: labourrate,
      panels: panels,
    };
  }

  return async (dispatch) => {
    const response = await SealerCost(params).get();
    const estimate = {
      recordId: uuidv4(),
      totalMaterial: response.data.totalMaterial,
      totalLabourCost: response.data.totalLabourCost,
      totalLabourMins: response.data.totalLabourMins,
      total: response.data.totalMaterial + response.data.totalLabourCost,
      name: name,
      sealerlength: sealerlength,
      sealerwidth: sealerwidth,
      sealerid: id,
      area: area,
      panels: panels,
      shade: shade,
    };

    dispatch({ type: CREATE_ESTIMATE_ITEM, payload: estimate });
  };
};

export const setLastEstimateKey = (key) => {
  return {
    type: SET_LAST_ESTIMATE_KEY,
    payload: key,
  };
};

export const clearNewEstimateItems = () => {
  return {
    type: CLEAR_NEW_ESTIMATE_ITEMS,
  };
};

export const setEstimateSearchRange = (range) => {
  return {
    type: SET_ESTIMATE_SEARCH_RANGE,
    payload: range,
  };
};

export const selectEstimate = (estimateData) => {
  return {
    type: SELECT_ESTIMATE,
    payload: estimateData,
  };
};

export const cancelEstimateSave = () => {
  return {
    type: CANCEL_SAVE,
  };
};

export const selectSealer = (selectedSealer) => {
  return (dispatch) => {
    dispatch({ type: SELECT_SEALER, payload: selectedSealer });
    dispatch({ type: HIDE_SEALER_NOT_SELECTED_ERROR, payload: null });
    dispatch({ type: CLEAR_SELECTED_SHADE });
  };
};

export const selectEstimateItem = (selectedItem) => {
  return {
    type: SELECT_ESTIMATE_ITEM,
    payload: selectedItem,
  };
};

export const setPaintShade = (shade) => {
  return { type: SET_SELECTED_SHADE, payload: shade };
};

export const deleteEstimateItem = (id) => {
  return { type: DELETE_ESTIMATE_ITEM, payload: id };
};

export const passwordResetRequestSuccess = (message) => {
  return {
    type: PASSWORD_RESET_REQUEST_SUCCESS,
    payload: message,
  };
};

export const setResetUsername = (uname) => {
  return {
    type: SET_RESET_USERNAME,
    payload: uname,
  };
};

export const createUserSettings = (settings) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await UserSettings().post("", settings);
    } catch (e) {
      return e;
    }
    dispatch({ type: CREATE_USER_SETTINGS, payload: response.data });
  };
};

export const updateUserSettings = (settings, profileSettingType) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await UserSettings(settings.email).patch("", settings);
    } catch (e) {
      console.log(e.message);
      return e;
    }
    switch (profileSettingType) {
      case profileSettingTypes.CALCULATION_METHOD:
        dispatch({
          type: SAVED_CALC_METHOD,
          payload: "Calculation method updated.",
        });
        break;
      case profileSettingTypes.CURRENCY:
        dispatch({
          type: SAVED_CURRENCY,
          payload: "Currency updated.",
        });
        break;
      case profileSettingTypes.WORK_UNITS_PER_HOUR:
        dispatch({
          type: SAVED_LABOUR_UNITS_PER_HOUR,
          payload: "Labour Units updated.",
        });
        break;
      default:
        break;
    }
    dispatch({ type: CREATE_USER_SETTINGS, payload: response.data });
  };
};

export const getUserSettings = (email) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await UserSettings(email).get();
    } catch (e) {
      console.log(response);
      return;
    }
    dispatch({ type: GET_USER_SETTINGS, payload: response.data });
  };
};

export const getSealers = () => {
  return async (dispatch) => {
    const response = await Sealers().get();
    dispatch({ type: GET_SEALERS, payload: response.data });
  };
};

export const getSealer = (id) => {
  return async (dispatch) => {
    const response = await Sealers(id).get();
    dispatch({ type: SELECT_SEALER, payload: response.data });
  };
};
