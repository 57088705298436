import { React, useState } from "react";
import "../components.css";
import { Button, Popup } from "semantic-ui-react";

const SealerItem = ({ sealer, onSealerSelect, selectedSealerId }) => {
  const [source, setSource] = useState(sealer.imageuri);

  const OnImageBtnClick = () => {
    if (!sealer.imageurialt) {
      setSource(sealer.imageuri);
    } else if (source === sealer.imageuri) {
      setSource(sealer.imageurialt);
    } else {
      setSource(sealer.imageuri);
    }
  };

  if (!sealer) {
    return <div className="ui text loader">Loading sealers</div>;
  }

  //hide alt image button of no alt image specified
  const altImageButtonStyle = sealer.imageurialt ? {} : { display: "none" };
  const iconClassName = `${
    sealer.id === selectedSealerId
      ? "large corner orange check circle outline icon"
      : ""
  }`;
  const cardClassName = `${
    sealer.id === selectedSealerId ? "ui card orange" : "ui card"
  }`;

  const popupstyle = {
    borderRadius: 5,
    opacity: 0.8,
    padding: "1em",
    backgroundColor: "green",
    color: "white",
    fontSize: "16px",
  };

  return (
    <div className={cardClassName}>
      <div
        data-tooltip="Click to select"
        data-position="top center"
        data-tip
        data-for="selectTip"
        className="ui medium bordered centered image"
      >
        <img
          onClick={() => onSealerSelect(sealer)}
          alt={sealer.name}
          src={source}
          className="sealer-card"
        ></img>
      </div>

      <div className="content">
        <div
          className="header sealer-card"
          onClick={() => onSealerSelect(sealer)}
          style={{
            fontSize: "1.2em",
            fontWeight: "500",
            fontFamily: "Raleway",
          }}
        >
          {sealer.name}
        </div>
        <div className="ui stackable two column grid">
          <div
            className="ui five wide column"
            style={{ marginTop: "1em", textAlign: "center" }}
          >
            <Popup
              hoverable
              content={
                <a href={sealer.produri} target="_blank">
                  <Popup
                    style={popupstyle}
                    content="Click to enlarge"
                    position="center"
                    trigger={
                      <img
                        width="300px"
                        alt={sealer.name}
                        src={sealer.produri}
                        className="sealer-card"
                      ></img>
                    }
                  />
                </a>
              }
              trigger={
                <Button
                  circular
                  size="tiny"
                  icon="info"
                  color="orange"
                ></Button>
              }
            />
          </div>
          <div
            className="ui six wide column"
            style={{ marginTop: "1em", textAlign: "center" }}
          >
            <div className="description">{sealer.id}</div>
          </div>
          <div
            className="ui five wide column"
            style={{ marginTop: "1em", textAlign: "right" }}
          >
            <Button
              style={altImageButtonStyle}
              data-tooltip="more images"
              circular
              size="tiny"
              icon="angle right"
              onClick={OnImageBtnClick}
            ></Button>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <i className={iconClassName} />
        </div>
      </div>
    </div>
  );
};

export default SealerItem;
