import React from "react";
import jsPDF from "jspdf";
import utils from "../../utilities";
import pdfHelper from "../../pdfHelper";
import _cralogo from "../../assets/cra.jpg";
import _sambralogo from "../../assets/sambra.jpg";
import { Button, Icon, Popup } from "semantic-ui-react";
import { env } from "../../apis/env";
import Base64 from "base-64";
import path from "path";
import AWS from "aws-sdk";
import { connect } from "react-redux";
import { getSealers } from "../../actions";

class WorksheetPDF extends React.Component {
  constructor(props) {
    super(props);
    this.pdf = new pdfHelper(props, {
      h1Color: "blue",
      textLineHeight: 2.5,
      yStart: 50,
      h2Color: "navy",
    });
  }

  componentDidMount() {
    this.props.getSealers();
  }

  download = () => {
    const _env = env();
    const u = new utils();
    const { sealers } = this.props;

    let items = [];
    sealers.forEach((sealer) => {
      items.push({
        head: `${u.nextNumber()}. ${sealer.name}`,
        imguri: sealer.imageuri,
        calculationtype: sealer.calculationtype,
      });
    });

    const textVerticalOffset = -75;
    const lineVerticalOffset = -70;
    const textLineHeight = 30;
    const textLineCount = 2;

    var doc = new jsPDF({
      orientation: "l",
      unit: "pt",
      format: "a4",
    });

    this.pdf.image(doc, _sambralogo, 2, 100, 50, false);
    this.pdf.border(doc);
    this.pdf.text(
      doc,
      "Automotive Sealer Calculator Capture Sheet",
      180,
      true,
      "h1",
      -20
    );
    this.pdf.image(doc, _cralogo, 650, 100, 50, true);
    this.pdf.line(doc, 1, 1, "gray");
    this.pdf.text(doc, "Job:", 1, false, null, 5);
    this.pdf.text(doc, "Reg no:", 205, true, null, 5);
    this.pdf.text(doc, "Paint:", 395, true, null, 5);
    this.pdf.text(doc, "Light", 470, true, null, 5);
    this.pdf.rectange(doc, 450, 140, 15, 15, "black");
    this.pdf.text(doc, "Dark", 540, true, null, 5);
    this.pdf.rectange(doc, 520, 140, 15, 15, "black");
    this.pdf.text(doc, "Insurer:", 595, true, null, 5);
    this.pdf.shortline(doc, 65, 1, "black", 120, -20);
    this.pdf.shortline(doc, 255, 1, "black", 120, -20);
    this.pdf.shortline(doc, 650, 1, "black", 120, -20);
    this.pdf.shortVerticalLine(doc, 195, 1, "silver", 30, true);
    this.pdf.shortVerticalLine(doc, 385, 1, "silver", 30, true);
    this.pdf.shortVerticalLine(doc, 585, 1, "silver", 30, true);
    items.forEach((item) => {
      //image
      const imageBucket = "sealercalc-sealer-thumbnails";
      const imageKey = item.imguri.substring(
        item.imguri.lastIndexOf("/") + 1,
        item.imguri.length
      );
      const signedUrlExpireSeconds = 60 * 1;
      const s3 = new AWS.S3({
        accessKeyId: _env.COGNITO_ACCESS_KEY_ID,
        region: _env.REGION,
        signatureVersion: "v4",
        secretAccessKey: _env.COGNITO_ACCESS_KEY,
      });

      const signedurl = s3.getSignedUrl("getObject", {
        Bucket: imageBucket,
        Key: imageKey,
        Expires: signedUrlExpireSeconds,
      });

      var img = new Image();
      img.style = "border: dashed 2px black;";
      img.crossOrigin = "";
      img.src = signedurl;
      this.pdf.image(doc, img, 60, 90, 90, false, {
        color: "silver",
        width: 2,
        padding: 5,
      });
      //sealer type heading
      this.pdf.text(doc, item.head, 220, true, "h2", textVerticalOffset);
      //dimensions
      if (item.calculationtype === "area") {
        for (let i = 1; i <= textLineCount; i++) {
          this.pdf.text(
            doc,
            `Panel ${i}`,
            200,
            true,
            null,
            textVerticalOffset + textLineHeight * i
          );
          this.pdf.text(
            doc,
            "Length",
            430,
            true,
            null,
            textVerticalOffset + textLineHeight * i
          );
          this.pdf.text(
            doc,
            "mm",
            540,
            true,
            null,
            textVerticalOffset + textLineHeight * i
          );
          this.pdf.text(
            doc,
            "Width",
            590,
            true,
            null,
            textVerticalOffset + textLineHeight * i
          );
          this.pdf.text(
            doc,
            "mm",
            700,
            true,
            null,
            textVerticalOffset + textLineHeight * i
          );
          this.pdf.shortline(
            doc,
            250,
            1,
            "black",
            150,
            lineVerticalOffset + textLineHeight * (i - 1)
          );
          this.pdf.shortline(
            doc,
            480,
            1,
            "black",
            50,
            lineVerticalOffset + textLineHeight * (i - 1)
          );
          this.pdf.shortline(
            doc,
            640,
            1,
            "black",
            50,
            lineVerticalOffset + textLineHeight * (i - 1)
          );
        }
      } else if (item.calculationtype === "each") {
        this.pdf.text(
          doc,
          "Number of Panels",
          200,
          true,
          null,
          textVerticalOffset + textLineHeight
        );
        this.pdf.shortline(doc, 330, 1, "black", 50, lineVerticalOffset);
      } else {
        for (let i = 1; i <= textLineCount; i++) {
          this.pdf.text(
            doc,
            `Panel ${i}`,
            200,
            true,
            null,
            textVerticalOffset + textLineHeight * i
          );
          this.pdf.text(
            doc,
            "Length",
            430,
            true,
            null,
            textVerticalOffset + textLineHeight * i
          );
          this.pdf.text(
            doc,
            "mm",
            540,
            true,
            null,
            textVerticalOffset + textLineHeight * i
          );
          this.pdf.shortline(
            doc,
            250,
            1,
            "black",
            150,
            lineVerticalOffset + textLineHeight * (i - 1)
          );
          this.pdf.shortline(
            doc,
            480,
            1,
            "black",
            50,
            lineVerticalOffset + textLineHeight * (i - 1)
          );
        }
      }
    });

    doc.save("Worksheet.pdf");
  };

  render() {
    return (
      <>
        <Popup
          trigger={
            <Button icon circular compact basic onClick={this.download}>
              <Icon size="large" name="sticky note" color="orange"></Icon>
            </Button>
          }
          content="Download Capture Sheet"
          position="left center"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sealers: state.sealers,
  };
};

export default connect(mapStateToProps, { getSealers })(WorksheetPDF);
