import React from "react";
import Menu from "../components/Menu";
import Update from "../components/estimates/Update";
import { ESTIMATE_ITEMS } from "../components/menuItemTypes";

const estimateUpdateView = (props) => {
  return (
    <>
      <Menu
        contentView={<Update estimateId={props.match.params.id} />}
        renderMenuFor={ESTIMATE_ITEMS}
      />
    </>
  );
};

export default estimateUpdateView;
