import React from "react";
import { connect } from "react-redux";
import { getEstimate, getUserSettings } from "../../actions";
import EstimateItemDetail from "./EstimateItemDetail";
import utils from "../../utilities";
import { isMobile } from "react-device-detect";

class EstimateItemList extends React.Component {
  componentDidMount() {
    this.props.getEstimate(this.props.estimateId);
    this.props.getUserSettings(this.props.email);
  }

  renderList() {
    const u = new utils();
    let itemNo = 0;
    return this.props.estimateItems.map((estimateItem) => {
      itemNo = u.nextNumber();
      return (
        <EstimateItemDetail
          estimateItem={estimateItem}
          itemNumber={itemNo}
          key={itemNo}
          currency={this.props.currency}
        />
      );
    });
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    if (this.props.estimateItems) {
      return (
        <div className={className}>
          <h3 style={{ marginBottom: "1.5em" }}>
            Items for {this.props.estimateName}
          </h3>
          {this.renderList()}
        </div>
      );
    } else {
      return (
        <div className="ui very padded segment">
          <div className="ui loading segment">
            <p>Loading...</p>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    estimateItems: state.estimates.estimate.items,
    estimateName: state.estimates.selectedName,
    estimateId: state.estimates.selectedId,
    email: state.auth.email,
    currency: state.user.settings.setitems.currency,
  };
};

export default connect(mapStateToProps, { getEstimate, getUserSettings })(
  EstimateItemList
);
