import React from "react";
import { connect } from "react-redux";
import { getSealers, selectSealer } from "../../actions";
import SealerItem from "./SealerItem";
import "../components.css";
import { env } from "../../apis/env";

class SealerList extends React.Component {
  _env = env();

  componentDidMount() {
    this.props.getSealers();
  }

  renderSealerList() {
    return this.props.sealers.map((sealer) => {
      return (
        <SealerItem
          key={sealer.id}
          sealer={sealer}
          onSealerSelect={this.onSealerSelect}
          selectedSealerId={this.props.currentSelectedSealerID}
        />
      );
    });
  }

  onSealerSelect = (sealer) => {
    this.props.selectSealer(sealer);
  };

  render() {
    const className = `ui red ${
      this.props.estimateFormActions.itemAdditionFailed ? "" : "hidden"
    } message`;
    if (this.props.sealers) {
      return (
        <div>
          <div className="ui stackable two column grid">
            <div
              className="ui eight wide column"
              style={{ marginBottom: "2em" }}
            >
              <h3>Available Sealers</h3>
            </div>
            <div
              className="ui eight wide column"
              style={{
                paddingBottom: "2em",
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              (Sealer prices last update: {this._env.LAST_SEALER_RATE_REVISION})
            </div>
          </div>
          <div className="ui five stackable cards">
            {this.renderSealerList()}
          </div>
          <div className={className}>
            {this.props.estimateFormActions.message}
          </div>
        </div>
      );
    } else {
      return (
        <div class="ui active inverted dimmer">
          <div class="ui text loader">Loading</div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    sealers: state.sealers,
    currentSelectedSealerID: state.selectedSealer.id,
    estimateFormActions: state.estimateFormActions,
  };
};

export default connect(mapStateToProps, {
  getSealers: getSealers,
  selectSealer: selectSealer,
})(SealerList);
