import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Button, Icon, Form, Grid, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { USE_LABOUR_RATE } from "../userProfile/profileSettingTypes";
import {
  updateEstimateItem,
  updateEstimate,
  resetSaveSuccess,
} from "../../actions";
import { compileDataForSave } from "../../apis/Estimates";
import history from "../../history";
import { isMobile } from "react-device-detect";

class UpdateModal extends React.Component {
  state = { paintShade: null, panels: null };

  cancelSave = () => {
    history.push(`/update/${this.props.estimate.id}`);
  };

  executeSave = () => {
    const data = compileDataForSave(
      this.props.estimate.id,
      this.props.estimate.estimatename,
      this.props.estimate.insurername,
      this.props.estimate.regnumber,
      this.props.estimateItems,
      this.props.workUnitsPerHour,
      this.props.userName,
      this.props.userId
    );
    this.props.updateEstimate(data, this.props.estimate.id);
  };

  onSubmit = async (formValues) => {
    await this.props
      .updateEstimateItem(
        formValues,
        this.props.selectedSealer.id,
        this.props.selectedSealer.name,
        this.state.panels,
        this.state.paintShade,
        this.props.estimateitem.recordId
      )
      .then(() => {
        this.executeSave();
      });
    //reset paint shade - will give err for other sealers if passed as parameter
    this.setState({ paintShade: null });
    this.setState({ panels: null });
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 3000);
  };

  renderInput = ({ input, label, meta, labelText, id, autoFocus, style }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "2em" }}>
        <div className="ui right labeled input">
          <div className="ui basic label">{labelText}</div>
          <input {...input} id={id} autoComplete="off" autoFocus={autoFocus} />
          <div className="ui green basic label">{label}</div>
        </div>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderError = ({ error, submitFailed }) => {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  };

  renderCancelText = () => {
    if (this.props.saveSuccess) {
      return "Done";
    } else {
      return "Cancel";
    }
  };

  renderResult = () => {
    if (this.props.errResult) {
      return <div className="ui error message">{this.props.errResult}</div>;
    } else if (this.props.saveSuccess) {
      return <div className="ui success message">Quote updated and saved.</div>;
    }
  };

  onDropdownChange = (e, { value }) => {
    this.setState({ paintShade: value });
  };

  renderDropDown = ({ style, defaultValue }) => {
    const shadeOptions = [
      { key: "light", text: "Light", value: "light" },
      { key: "dark", text: "Dark", value: "dark" },
    ];

    return (
      <Dropdown
        style={style}
        defaultValue={defaultValue}
        fluid
        selection
        options={shadeOptions}
        onChange={this.onDropdownChange}
      />
    );
  };

  renderShadeDropdown() {
    const shadeStyle = {
      marginBottom: "2em",
    };
    if (this.props.selectedSealer.sealerunitcostwhite) {
      return (
        <>
          <h5 style={{ marginBottom: "1em" }}>Paint Color Shade</h5>
          <Field
            style={shadeStyle}
            defaultValue={this.props.initialValues.shade}
            name="shade"
            component={this.renderDropDown}
            id="shadeSelect"
            placeholder="Vehicle paint shade"
          />
        </>
      );
    }
  }

  renderLabourRateInput() {
    if (this.props.calculationMethod === USE_LABOUR_RATE) {
      return (
        <Field
          name="labourrate"
          component={this.renderInput}
          id="labourInput"
          labelText="Rate"
          label={this.props.currency.name}
          onChange={this.props.resetSaveSuccess}
        />
      );
    }
  }

  onQtyDropdownChange = (e, { value }) => {
    this.setState({ panels: value });
  };

  renderQtyDropDown = ({ defaultValue }) => {
    const qtyOptions = [
      { key: "1", text: "1", value: "1" },
      { key: "2", text: "2", value: "2" },
      { key: "3", text: "3", value: "3" },
      { key: "4", text: "4", value: "4" },
      { key: "5", text: "5", value: "5" },
      { key: "6", text: "6", value: "6" },
      { key: "7", text: "7", value: "7" },
      { key: "8", text: "8", value: "8" },
      { key: "9", text: "9", value: "9" },
      { key: "10", text: "10", value: "10" },
    ];

    let style = null;
    if (isMobile) {
      style = {
        width: "90%",
        marginBottom: "1em",
      };
    } else {
      style = {
        maxWidth: "100%",
        marginBottom: "1em",
      };
    }

    return (
      <>
        <h5 style={{ marginBottom: "1em" }}>Number of Panels</h5>
        <Dropdown
          selection
          style={style}
          options={qtyOptions}
          onChange={this.onQtyDropdownChange}
          defaultValue={defaultValue}
        />
      </>
    );
  };

  renderPanelQtyDropdown() {
    if (this.props.selectedSealer.calculationtype === "each") {
      return (
        <div style={{ marginBottom: "2em" }}>
          <Field
            name="panels"
            component={this.renderQtyDropDown}
            id="panelqty"
            defaultValue={this.props.initialValues.panels}
          />
        </div>
      );
    }
  }

  renderPanelQtyInputs() {
    if (this.props.selectedSealer.calculationtype === "each") {
      return (
        <Form.Field>
          {this.renderPanelQtyDropdown()}
          {this.renderLabourRateInput()}
        </Form.Field>
      );
    }
    return;
  }

  renderLengthInputs() {
    let style = {};
    if (isMobile) {
      style = {
        maxWidth: "90%",
      };
    }
    if (this.props.selectedSealer.calculationtype === "length") {
      return (
        <Form.Field>
          {this.renderShadeDropdown()}
          {this.renderLabourRateInput()}
          <Field
            style={style}
            name="sealerlength"
            id="lenInput"
            component={this.renderInput}
            labelText="Length"
            label="mm"
            autoFocus
            onChange={this.props.resetSaveSuccess}
          />
        </Form.Field>
      );
    }
    return;
  }

  renderAreaInputs() {
    if (this.props.selectedSealer.calculationtype === "area") {
      return (
        <Form.Field>
          {this.renderShadeDropdown()}
          {this.renderLabourRateInput()}
          <Field
            name="sealerlength"
            id="lenInput"
            component={this.renderInput}
            labelText="Length"
            label="mm"
            autoFocus
          />
          <Field
            name="sealerwidth"
            id="widthInput"
            component={this.renderInput}
            labelText="Width"
            label="mm"
          />
        </Form.Field>
      );
    }
    return;
  }

  render() {
    return ReactDOM.createPortal(
      <div className="ui dimmer modals visible active">
        <div className="ui standard modal visible active">
          <div className="ui segment padded">
            <h3>Edit {this.props.estimateitem.name}</h3>
            <Grid stackable padded>
              <Grid.Column>
                <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                  {this.renderAreaInputs()}
                  {this.renderLengthInputs()}
                  {this.renderPanelQtyInputs()}
                  <Button.Group className="ui right floated">
                    <Button
                      loading={this.props.busySaving}
                      primary
                      icon
                      labelPosition="right"
                    >
                      Save
                      <Icon name="save"></Icon>
                    </Button>
                    <Button.Or />
                    <Button type="button" onClick={this.cancelSave}>
                      {this.renderCancelText()}
                    </Button>
                  </Button.Group>
                </Form>
              </Grid.Column>
            </Grid>
            <div className="content">{this.renderResult()}</div>
          </div>
        </div>
      </div>,
      document.querySelector("#modal")
    );
  }
}

const formWrapped = reduxForm({
  form: "UpdateModal",
  enableReinitialize: true,
})(UpdateModal);

const mapStateToProps = (state) => {
  return {
    estimate: state.estimates.estimate,
    workUnitsPerHour: state.user.settings.setitems.labourUnitsPerHour,
    selectedSealer: state.selectedSealer,
    estimateItems: state.newEstimateItems,
    estimateitem: state.estimates.selectedItem,
    calculationMethod: state.user.settings.setitems.calculationMethod,
    currency: state.user.settings.setitems.currency
      ? state.user.settings.setitems.currency
      : { name: "ZAR" },
    userName: state.auth.email,
    userId: state.auth.userId,
    busySaving: state.api.busySaving,
    saveSuccess: state.api.saveSuccess,
    errResult: state.api.saveError,
  };
};

export default connect(mapStateToProps, {
  updateEstimateItem,
  updateEstimate,
  resetSaveSuccess,
})(formWrapped);
