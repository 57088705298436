import { env } from "../../apis/env";

class SessionManager {
  //TTL = seconds
  static setWithExpiry(key, value, ttl) {
    const now = new Date();

    const item = {
      value: value,
      expiry: now.getTime() + ttl * 1000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  static getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  static startSession(userId, email, authorizor) {
    const _env = env();
    const ttl = _env.TTL_MINS;
    this.setWithExpiry("email", email, ttl);
    localStorage.setItem("userId", userId);
    localStorage.setItem("authorizor", authorizor);
    localStorage.setItem("lastLoginUser", email);
  }

  static terminateSession() {
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("authorizor");
    localStorage.removeItem("firstName");
  }

  static checkAuth() {
    let auth = {};
    auth.authorizor = localStorage.getItem("authorizor");
    auth.email = this.getWithExpiry("email");
    auth.userId = localStorage.getItem("userId");
    auth.firstName = localStorage.getItem("firstName");
    return auth;
  }
}

export default SessionManager;
