import {
  SHOW_SEALER_NOT_SELECTED_ERROR,
  HIDE_SEALER_NOT_SELECTED_ERROR,
  SET_SELECTED_SHADE,
  CLEAR_SELECTED_SHADE,
} from "../actions/types";

const INITIAL_STATE = {
  itemAdditionFailed: false,
  failureReason: null,
  message: null,
};

const estimateItemCreateFormReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_SEALER_NOT_SELECTED_ERROR:
      return {
        ...state,
        itemAdditionFailed: true,
        failureReason: action.payload.cause,
        message: action.payload.message,
      };
    case HIDE_SEALER_NOT_SELECTED_ERROR:
      return {
        ...state,
        itemAdditionFailed: false,
        failureReason: action.payload,
        message: action.payload,
      };
    case SET_SELECTED_SHADE:
      return { ...state, selectedPaintShade: action.payload };
    case CLEAR_SELECTED_SHADE:
      return { ...state, selectedPaintShade: null };
    default:
      return state;
  }
};

export default estimateItemCreateFormReducer;
