import { SELECT_SEALER } from "../actions/types";

const selectedSealerReducer = (state = {}, action) => {
  switch (action.type) {
    case SELECT_SEALER:
      return action.payload;
    default:
      return state;
  }
};

export default selectedSealerReducer;
