import React from "react";

export const privacyHTML = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "3em 3em 3em 3em",
        lineHeight: "1.5em",
      }}
    >
      <h2>Privacy Policy</h2>
      <p>
        <strong>Last updated: August 31, 2021</strong>
      </p>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of his/her information when "the client"
        makes use the Service and tells "the client" about his/her privacy
        rights and how the law protects "the client".
      </p>
      <p>
        We use "the client's" Personal data to provide and improve the Service.
        By using the Service, "the client" agrees to the collection and use of
        information in accordance with this Privacy Policy.
      </p>
      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Account</strong> means a unique account created for "the
            client" to access our Service or parts of our Service.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to Lead Rocket Digital, 12 Lambert Avenue,
            Randpark, Gauteng.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Cookies</strong> are small files that are placed on "the
            client's" computer, mobile device or any other device by a website,
            containing the details of his/her browsing history on that website
            among its many uses.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Country</strong> refers to: South Africa
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Service</strong> refers to the Website.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Website</strong> refers to autosealercalc.com, accessible
            from
            <a
              href="autosealercalc.com"
              rel="external nofollow noopener"
              target="_blank"
            >
              <span> autosealercalc.com</span>
            </a>
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>"The client"</strong> means the individual accessing or
            using the Service, or the company, or other legal entity on behalf
            of which such individual is accessing or using the Service, as
            applicable.
          </p>
        </li>
      </ul>
      <h2>Collecting and Using "the Client's" Personal Data</h2>
      <h3>Types of Data Collected</h3>
      <h4>Personal Data</h4>
      <p>
        While using Our Service, We may ask "the client" to provide Us with
        certain personally identifiable information that can be used to contact
        or identify "the client". Personally identifiable information may
        include, but is not limited to:
      </p>
      <ul>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>Email address</p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>First name and last name</p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>Phone number</p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>Usage Data</p>
        </li>
      </ul>
      <h4>Usage Data</h4>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>
        Usage Data may include information such as "the client's" Device's
        Internet Protocol address (e.g. IP address), browser type, browser
        version, the pages of our Service that "the client" visits, the time and
        date of his/her visit, the time spent on those pages, unique device
        identifiers and other diagnostic data.
      </p>
      <p>
        When "the client" accesses the Service by or through a mobile device, We
        may collect certain information automatically, including, but not
        limited to, the type of mobile device "the client" uses, "the client's"
        mobile device unique ID, the IP address of "the client's" mobile device,
        "the client's" mobile operating system, the type of mobile Internet
        browser "the client" uses, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        We may also collect information that "the client's" browser sends
        whenever "the client" visit our Service or when "the client" accesses
        the Service by or through a mobile device.
      </p>
      <h4>Tracking Technologies and Cookies</h4>
      <p>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </p>
      <ul>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <strong>Cookies or Browser Cookies.</strong> A cookie is a small file
          placed on "the client's" Device. "The client" can instruct his/her
          browser to refuse all Cookies or to indicate when a Cookie is being
          sent. However, if "the client" does not accept Cookies, "the client"
          may not be able to use some parts of our Service. Unless he/she has
          adjusted his/her browser setting so that it will refuse Cookies, our
          Service may use Cookies.
        </li>
      </ul>
      <p>
        Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
        Persistent Cookies remain on "the client's" personal computer or mobile
        device when "the client" goes offline, while Session Cookies are deleted
        as soon as "the client" closes his/her web browser.
      </p>
      <p>
        We use both Session and Persistent Cookies for the purposes set out
        below:
      </p>
      <ul>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Necessary / Essential Cookies</strong>
          </p>
          <p>Type: Session Cookies</p>
          <p>Administered by: Us</p>
          <p>
            Purpose: These Cookies are essential to provide "the client" with
            services available through the Website and to enable "the client" to
            use some of its features. They help to authenticate users and
            prevent fraudulent use of user accounts. Without these Cookies, the
            services that "the client" has asked for cannot be provided, and We
            only use these Cookies to provide "the client" with those services.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Cookies Policy / Notice Acceptance Cookies</strong>
          </p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>Functionality Cookies</strong>
          </p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>
            Purpose: These Cookies allow us to remember choices "the client"
            makes when "the client" uses the Website, such as remembering your
            login details or language preference. The purpose of these Cookies
            is to provide "the client" with a more personal experience and to
            avoid "the client" having to re-enter your preferences every time
            "the client" uses the Website.
          </p>
        </li>
      </ul>
      <h3>Use of "the Client's" Personal Data</h3>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>To provide and maintain our Service</strong>, including to
            monitor the usage of our Service.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>To manage "the Client's" Account:</strong> to manage "the
            client's" registration as a user of the Service. The Personal Data
            "the client" provides can give "the client" access to different
            functionalities of the Service that are available to "the client" as
            a registered user.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>To contact "the client":</strong> To contact "the client" by
            email, telephone calls, SMS, or other equivalent forms of electronic
            communication, such as a mobile application's push notifications
            regarding updates or informative communications related to the
            functionalities, products or contracted services, including the
            security updates, when necessary or reasonable for their
            implementation.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>To provide "the client"</strong> with news, special offers
            and general information about other goods, services and events which
            we offer that are similar to those that you have already purchased
            or enquired about unless "the client" opted not to receive such
            information.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>To manage "the client's" requests:</strong> To attend and
            manage "the client's" requests to Us.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>For business transfers:</strong> We may use "the client's"
            information to evaluate or conduct a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of Our assets, whether as a going concern or
            as part of bankruptcy, liquidation, or similar proceeding, in which
            Personal Data held by Us about our Service users is among the assets
            transferred.
          </p>
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <p>
            <strong>For other purposes</strong>: We may use "the client's"
            information for other purposes, such as data analysis, identifying
            usage trends, determining the effectiveness of our promotional
            campaigns and to evaluate and improve our Service, products,
            services, marketing and your experience.
          </p>
        </li>
      </ul>
      <p>
        We may share "the client's" personal information in the following
        situations:
      </p>
      <ul>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <strong>With Service Providers:</strong> We may share "the client's"
          personal information with Service Providers to monitor and analyze the
          use of our Service, to contact "the client".
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <strong>For business transfers:</strong> We may share or transfer "the
          client's" personal information in connection with, or during
          negotiations of, any merger, sale of Company assets, financing, or
          acquisition of all or a portion of Our business to another company.
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <strong>With Affiliates:</strong> We may share "the client's"
          information with Our affiliates, in which case we will require those
          affiliates to honor this Privacy Policy. Affiliates include joint
          venture partners or other companies that We control or that are under
          common control with Us.
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <strong>With business partners:</strong> We may share "the client's"
          information with Our business partners to offer "the client" certain
          products, services or promotions.
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          <strong>With "the Client's" consent</strong>: We may disclose "the
          client's" personal information for any other purpose with his/her
          consent.
        </li>
      </ul>
      <h3>Retention of "the Client's" Personal Data</h3>
      <p>
        The Company will retain "the client's" Personal Data only for as long as
        is necessary for the purposes set out in this Privacy Policy. We will
        retain and use "the client's" Personal Data to the extent necessary to
        comply with our legal obligations (for example, if we are required to
        retain your data to comply with applicable laws), resolve disputes, and
        enforce our legal agreements and policies.
      </p>
      <p>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>
      <h3>Transfer of "the Client's" Personal Data</h3>
      <p>
        "The client's" information, including Personal Data, is processed at the
        Company's operating offices and in any other places where the parties
        involved in the processing are located. It means that this information
        may be transferred to — and maintained on — computers located outside of
        "the client's" state, province, country or other governmental
        jurisdiction where the data protection laws may differ than those from
        "the client's" jurisdiction.
      </p>
      <p>
        "The client's" consent to this Privacy Policy followed by "the client's"
        submission of such information represents "the client's" agreement to
        that transfer.
      </p>
      <p>
        The Company will take all steps reasonably necessary to ensure that "the
        client's" data is treated securely and in accordance with this Privacy
        Policy and no transfer of "the client's" Personal Data will take place
        to an organization or a country unless there are adequate controls in
        place including the security of "the client's" data and other personal
        information.
      </p>
      <h3>Disclosure of "the Client's" Personal Data</h3>
      <h4>Business Transactions</h4>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, "the
        client's" Personal Data may be transferred. We will provide notice
        before "the client's" Personal Data is transferred and becomes subject
        to a different Privacy Policy.
      </p>
      <h4>Law enforcement</h4>
      <p>
        Under certain circumstances, the Company may be required to disclose
        "the client's" Personal Data if required to do so by law or in response
        to valid requests by public authorities (e.g. a court or a government
        agency).
      </p>
      <h4>Other legal requirements</h4>
      <p>
        The Company may disclose "the client's" Personal Data in the good faith
        belief that such action is necessary to:
      </p>
      <ul>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          Comply with a legal obligation
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          Protect and defend the rights or property of the Company
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          Protect against legal liability
        </li>
      </ul>
      <h3>Security of "the Client's" Personal Data</h3>
      <p>
        The security of "the client's" Personal Data is important to Us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While We strive to use commercially
        acceptable means (such as SSL encryption) to protect "the client's"
        Personal Data, We cannot guarantee its absolute security.
      </p>
      <h2>Children's Privacy</h2>
      <p>
        Our Service does not address anyone under the age of 18. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 18. If "the client" is a parent or guardian and "the client"
        is aware that "the client's" child has provided Us with Personal Data,
        please contact Us. If We become aware that We have collected Personal
        Data from anyone under the age of 18 without verification of parental
        consent, We take steps to remove that information from Our servers.
      </p>
      <h2>Links to Other Websites</h2>
      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If "the client" clicks on a third party link, "the client" will be
        directed to that third party's site. We strongly advise "the client" to
        review the Privacy Policy of every site "the client" visits.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may update Our Privacy Policy from time to time. We will notify "the
        client" of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let "the client" know via email and/or a prominent notice on Our
        Service, prior to the change becoming effective and update the
        &quot;Last updated&quot; date at the top of this Privacy Policy.
      </p>
      <p>
        "The client" is advised to review this Privacy Policy periodically for
        any changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h2>Contact Us</h2>
      <p>
        If "the client" has any questions about this Privacy Policy, "the
        client" can contact us:
      </p>
      <ul>
        <li style={{ lineHeight: "1.5em", marginTop: "2em" }}>
          By email:{" "}
          <a href="mailto:techsupport@leadrocketdigital.com">
            techsupport@leadrocketdigital.com
          </a>
        </li>
      </ul>
    </div>
  );
};
