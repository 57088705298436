import React from "react";
import "../components.css";
import history from "../../history";

const NewEstimateItemDetail = ({
  estimateItem,
  workUnitsPerHour,
  onDeleteClick,
  itemNumber,
  isUpdate,
  estimateId,
  hideDeleteButton,
}) => {
  const renderMeasurements = () => {
    if (estimateItem.area) {
      return (
        <div
          data-tooltip="Sealer Area"
          style={{ paddingTop: "1%" }}
          className="description"
        >
          <span>
            <i className="large expand arrows alternate icon"></i>
          </span>
          <span style={{ paddingLeft: "1%" }}>
            {estimateItem.area.toFixed(2)}
          </span>
        </div>
      );
    } else if (estimateItem.sealerlength) {
      return (
        <div
          data-tooltip="Sealer Length"
          style={{ paddingTop: "1%" }}
          className="description"
        >
          <span>
            <i className="large arrows alternate horizontal icon"></i>
          </span>
          <span style={{ paddingLeft: "1%" }}>{estimateItem.sealerlength}</span>
        </div>
      );
    } else {
      //panels
      return (
        <div
          data-tooltip="Number of panels"
          style={{ paddingTop: "1%" }}
          className="description"
        >
          <span>
            <i className="large list ol icon"></i>
          </span>
          <span style={{ paddingLeft: "1%" }}>{estimateItem.panels}</span>
        </div>
      );
    }
  };

  const renderTotalCost = () => {
    if (estimateItem.totalLabourCost) {
      return (
        <div
          data-tooltip="Total Cost"
          style={{ paddingTop: "1%" }}
          className="description"
        >
          <span>
            <i className="large money bill alternate icon"></i>
          </span>
          <span style={{ paddingLeft: "5%" }}>
            {(
              estimateItem.totalLabourCost + estimateItem.totalMaterial
            ).toFixed(2)}
          </span>
        </div>
      );
    }
  };

  const renderLabour = () => {
    let labourCostTime = "";
    let tipText = "";
    if (estimateItem.totalLabourMins) {
      labourCostTime = (
        estimateItem.totalLabourMins /
        (60 / workUnitsPerHour)
      ).toFixed(2);
      tipText = "Labour Units";
    } else {
      labourCostTime = estimateItem.totalLabourCost.toFixed(2);
      tipText = "Labour Cost";
    }
    return (
      <div
        data-tooltip={tipText}
        style={{ paddingTop: "1%" }}
        className="description"
      >
        <span>
          <i className="large stopwatch icon"></i>
        </span>
        <span style={{ paddingLeft: "1%" }}>{labourCostTime}</span>
      </div>
    );
  };

  const deleteClick = () => {
    if (isUpdate) {
      history.push(`/deleteitem/${estimateId}/${estimateItem.recordId}`);
    } else {
      onDeleteClick(estimateItem.recordId);
    }
  };

  const renderDeleteButton = () => {
    //if updating estimate and only 1 item then do not render delete button
    if (hideDeleteButton && isUpdate) {
      return;
    }
    return (
      <div className="extra">
        <button
          data-tooltip="Delete Item"
          onClick={deleteClick}
          className="ui right floated basic circular compact red icon button"
        >
          <i className="x icon"></i>
        </button>
      </div>
    );
  };

  const renderUpdateButton = () => {
    if (isUpdate) {
      return (
        <div className="extra">
          <button
            data-tooltip="Edit Item"
            onClick={() =>
              history.push(`/updateitem/${estimateId}/${estimateItem.recordId}`)
            }
            className="ui right floated basic circular compact green icon button"
          >
            <i className="edit icon"></i>
          </button>
        </div>
      );
    }
  };

  return (
    <>
      <div className="ui stackable four column grid">
        <div className="one wide column">
          <div className="item" key={estimateItem.recordId}>
            <div className="narrow-padding narrow-right-margin vertical-align-middle">
              <h2>{itemNumber}</h2>
            </div>
          </div>
        </div>
        <div className="five wide column">
          <div className="content">
            <div style={{ fontWeight: "bold" }} className="header">
              {estimateItem.name}
            </div>
          </div>
        </div>
        <div className="two wide column">{renderMeasurements()}</div>
        <div className="two wide column">
          <div
            data-tooltip="Material Cost"
            style={{ paddingTop: "1%" }}
            className="description"
          >
            <span>
              <i className="large paint brush icon"></i>
            </span>
            <span style={{ paddingLeft: "1%" }}>
              {estimateItem.totalMaterial.toFixed(2)}
            </span>
          </div>
        </div>
        <div className="two wide column">{renderLabour()}</div>
        <div className="two wide column">{renderTotalCost()}</div>
        <div className="one wide column">{renderUpdateButton()}</div>
        <div className="one wide column">{renderDeleteButton()}</div>
      </div>
      <div className="ui divider"></div>
    </>
  );
};

export default NewEstimateItemDetail;
