import React from "react";
import { connect } from "react-redux";
import { signIn, signOut, setUserFirstName } from "../../actions";
import SessionManager from "../session/SessionManager";
import history from "../../history";
import { env } from "../../apis/env";

class GoogleSignIn extends React.Component {
  componentDidMount() {
    const _env = env();
    /*We need to use load method to load the part of library we want to use.
        gapi available on windows scope. We load the gapi script in index.html
        Need to have callback as 2nd argument of load and call client init with
        client ID and scope of API use e.g. email for OAuth.
        Init returns promise and */
    window.gapi.load("client:auth2", () => {
      window.gapi.client
        .init({
          clientId: _env.GCLIENT_ID,
          scope: _env.GCLIENT_SCOPE,
          // clientId:
          //   "603184432625-53nbu8fucp1sfru19rkd93qe904c22qh.apps.googleusercontent.com",
          // scope: "email",
        })
        .then(() => {
          this.auth = window.gapi.auth2.getAuthInstance();
          this.OnAuthChange(this.auth.isSignedIn.get());
          this.auth.isSignedIn.listen(this.OnAuthChange);
        });
    });
  }

  OnAuthChange = (isSignedIn) => {
    const user = this.auth.currentUser.get();
    if (isSignedIn) {
      this.props.signIn(
        user.getId(),
        user.getBasicProfile().getEmail(),
        "GAPI"
      );
      this.props.setUserFirstName(user.getBasicProfile().getGivenName());
      localStorage.setItem("firstName", user.getBasicProfile().getGivenName());
      SessionManager.startSession(
        user.getId(),
        user.getBasicProfile().getEmail(),
        "GAPI"
      );
      history.push("/dashboard");
    } else {
      if (this.props.authorizor === "gapi") {
        this.props.signOut();
        SessionManager.terminateSession();
      }
    }
  };

  OnSignInClick = () => {
    this.auth.signIn();
  };

  OnSignOutClick = () => {
    this.auth.signOut();
  };

  renderAuthButton() {
    if (this.props.isSignedIn === null) {
      return null;
    } else if (this.props.isSignedIn) {
      return (
        <button
          onClick={this.OnSignOutClick}
          className="fluid ui red google button"
        >
          <i className="google icon"></i>
          Sign Out
        </button>
      );
    } else {
      return (
        <button
          onClick={this.OnSignInClick}
          className="fluid ui red google button"
        >
          <i className="google icon"></i>
          Continue With Google
        </button>
      );
    }
  }

  render() {
    return <div>{this.renderAuthButton()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    authorizor: state.auth.authorizor,
  };
};

export default connect(mapStateToProps, { signIn, signOut, setUserFirstName })(
  GoogleSignIn
);
