import React from "react";
import Modal from "../modal";
import { connect } from "react-redux";
import { getEstimate, updateEstimate, resetSaveSuccess } from "../../actions";
import { Link } from "react-router-dom";
import history from "../../history";
import { compileDataForSave } from "../../apis/Estimates";

class DeleteEstimateItem extends React.Component {
  componentDidMount() {
    this.props.getEstimate(this.props.match.params.qid);
  }

  deleteItem = (iid) => {
    //remove item from estimate items object by key
    delete this.props.estimateitems[iid];

    this.executeSave();
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 3000);
  };

  executeSave = () => {
    const data = compileDataForSave(
      this.props.estimate.id,
      this.props.estimate.estimatename,
      this.props.estimate.insurername,
      this.props.estimate.regnumber,
      this.props.estimateitems,
      this.props.workUnitsPerHour,
      this.props.userName,
      this.props.userId
    );
    this.props.updateEstimate(data, this.props.estimate.id);
  };

  renderActions() {
    //destructure item id from params
    const { iid, qid } = this.props.match.params;
    return (
      <>
        <button
          onClick={() => this.deleteItem(iid)}
          className="ui button negative"
        >
          Delete
        </button>
        <Link to={`/update/${qid}`} className="ui button">
          Cancel
        </Link>
      </>
    );
  }

  renderResult = () => {
    if (this.props.errResult) {
      return <div className="ui error message">{this.props.errResult}</div>;
    } else if (this.props.saveSuccess) {
      return (
        <div className="ui success message">
          Quote item deleted successfully.{" "}
        </div>
      );
    }
  };

  renderContent() {
    if (!this.props.estimate) {
      return "Are you sure you want to delete this item from the quote?";
    } else {
      return `Are you sure you want to delete this item from quote "${this.props.estimate.estimatename}"?`;
    }
  }

  render() {
    return (
      <Modal
        title="Delete Quote Item"
        content={this.renderContent()}
        actions={this.renderActions()}
        result={this.renderResult()}
        onDismiss={() => history.push(`/update/${this.props.match.params.iid}`)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    estimate: state.estimates.estimate,
    estimateitems: state.newEstimateItems,
    saveSuccess: state.api.saveSuccess,
    errResult: state.api.saveError,
    workUnitsPerHour: state.user.settings.setitems.labourUnitsPerHour,
    userName: state.auth.email,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps, {
  updateEstimate,
  getEstimate,
  resetSaveSuccess,
})(DeleteEstimateItem);
