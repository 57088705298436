import React from "react";
import Modal from "../modal";
import { connect } from "react-redux";
import { archiveEstimate, getEstimate } from "../../actions";
import { Link } from "react-router-dom";
import history from "../../history";

class Archive extends React.Component {
  componentDidMount() {
    this.props.getEstimate(this.props.match.params.id);
  }

  renderActions() {
    const { id } = this.props.match.params;
    return (
      <>
        <button
          onClick={() => this.props.archiveEstimate(id)}
          className="ui button negative"
        >
          Delete
        </button>
        <Link to="/" className="ui button">
          Cancel
        </Link>
      </>
    );
  }

  renderContent() {
    if (!this.props.estimate) {
      return "Are you sure you want to delete this quote?";
    } else {
      return `Are you sure you want to delete the quote "${this.props.estimate.estimatename}"?`;
    }
  }

  render() {
    return (
      <Modal
        title="Archive Quote"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={() => history.push("/dashboard")}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    estimate: state.estimates.estimate,
  };
};

export default connect(mapStateToProps, { archiveEstimate, getEstimate })(
  Archive
);
