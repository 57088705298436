import React from "react";
import AWSResetPassword from "./AWSResetPassword";
import { Segment, Grid, Header, Icon } from "semantic-ui-react";

class ResetPassword extends React.Component {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Grid stackable textAlign="center">
          <Grid.Column width="eight">
            <Segment padded="very">
              <Header icon>
                <Icon name="user secret" />
                Reset Password
              </Header>
              <AWSResetPassword />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default ResetPassword;
