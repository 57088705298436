import React from "react";
import { connect } from "react-redux";
import GoogleSignIn from "./GoogleSignIn";
import {
  Segment,
  Grid,
  Header,
  Icon,
  Divider,
  Image,
  Label,
} from "semantic-ui-react";
import AWSSignIn from "./AWSSignIn";
import { signIn } from "../../actions";
import { env } from "../../apis/env";

class SignIn extends React.Component {
  renderLogo = () => {
    return (
      <div>
        <Image
          centered
          width="350px"
          src="https://sealercalc-sealer-thumbnails.s3.af-south-1.amazonaws.com/Logo/sambra_cra.jpg"
        ></Image>
      </div>
    );
  };

  renderEnvironmentTag = () => {
    const _env = env();
    if (_env.ENV === "uat") {
      return (
        <div style={{ textAlign: "left", marginBottom: "2em" }}>
          <Label size="small" as="a" color="green" tag>
            User Acceptance Testing Environment
          </Label>
        </div>
      );
    }
  };

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Grid stackable textAlign="center">
          <Grid.Column width="eight">
            <Segment raised padded="very">
              {this.renderEnvironmentTag()}
              {this.renderLogo()}
              <Header icon>
                <Icon name="sign in" />
                Sign in
              </Header>
              <GoogleSignIn />
              <Divider horizontal>OR</Divider>
              <AWSSignIn />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default connect(null, { signIn: signIn })(SignIn);
