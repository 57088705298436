import React from "react";
import AWSConfirmEmail from "./AWSConfirmEmail";
import { Segment, Grid, Header, Icon } from "semantic-ui-react";

class ConfirmEmail extends React.Component {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Grid stackable textAlign="center">
          <Grid.Column width="eight">
            <Segment padded="very">
              <Header icon>
                <Icon name="user secret" />
                Activate Account
              </Header>
              <div className="ui positive message">
                Please enter the confirmation code sent to your email. If you do
                not find the email, please make sure to check your spam folder.
              </div>
              <AWSConfirmEmail />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default ConfirmEmail;
