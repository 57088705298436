import React from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import userPool from "./AWSUserPool";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Form, Icon, Input } from "semantic-ui-react";
import { signedUp, signUp, failSignup } from "../../actions";
import { Link } from "react-router-dom";
import history from "../../history";

class AWSSignUp extends React.Component {
  renderError = ({ submitFailed, error, visited }, id) => {
    if (id === "passwordInput") {
      if (visited && error) {
        return (
          <div className="ui error message">
            <div>{error}</div>
          </div>
        );
      }
    } else {
      if (submitFailed && error) {
        return (
          <div className="ui error message">
            <div>{error}</div>
          </div>
        );
      }
    }
  };

  onSubmit = (formValues) => {
    this.props.signUp();

    const phoneCodes = {
      "South Africa": "+27",
      "United Kingdom": "+44",
      "United States": "+1",
    };

    let attributeList = [];

    let emailData = {
      Name: "email",
      Value: formValues.email,
    };

    let nameData = {
      Name: "name",
      Value: formValues.firstname,
    };

    let surnameData = {
      Name: "family_name",
      Value: formValues.surname,
    };

    let phoneData = {
      Name: "phone_number",
      Value: phoneCodes[formValues.country] + formValues.phone,
    };

    attributeList.push(new CognitoUserAttribute(emailData));
    attributeList.push(new CognitoUserAttribute(nameData));
    attributeList.push(new CognitoUserAttribute(surnameData));
    attributeList.push(new CognitoUserAttribute(phoneData));

    userPool.signUp(
      formValues.email,
      formValues.password,
      attributeList,
      null,
      (err, data) => {
        if (err) {
          this.props.failSignup(err);
        } else {
          this.props.signedUp(data.user.username);
          //set local storage variable for activation form. Needed in case user refreshes page
          //before activating.
          localStorage.setItem("registerEmail", formValues.email);
          setTimeout(() => {
            history.push("/activate");
          }, 7500);
        }
      }
    );
  };

  renderInput = ({
    input,
    type,
    meta,
    placeholder,
    id,
    validateOn,
    autoFocus,
  }) => {
    const validateWhen = !validateOn ? meta.submitFailed : meta.visited;
    const className = `field ${meta.error && validateWhen ? "error" : ""}`;
    return (
      <div className={className}>
        <Input
          type={type}
          fluid
          {...input}
          id={id}
          placeholder={placeholder}
          autoFocus={autoFocus}
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderCountryInput = ({ input, type, meta, placeholder, id }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <Input
          list="countries"
          fluid
          labelPosition="left"
          type={type}
          {...input}
          id={id}
          placeholder={placeholder}
          autoComplete="off"
        ></Input>
        <datalist id="countries">
          <option value="South Africa"></option>
          <option value="United Kingdom"></option>
          <option value="United States"></option>
        </datalist>
        {this.renderError(meta)}
      </div>
    );
  };

  renderResult(isSignedUp, email, error) {
    if (error) {
      return <div className="ui error message">{error.message}</div>;
    }

    if (isSignedUp) {
      return (
        <div className="ui success message">
          <div className="header">
            Successfully signed up with user id {email}.
          </div>
          <p>
            We sent you an email with a verification code. After completing the
            verification you may sign-in.
          </p>
          <div>
            You will be redirected momentarily...
            <Link to="/activate">Click here to activate your account</Link>
          </div>
        </div>
      );
    }
    return "";
  }

  render() {
    return (
      <div>
        <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Form.Field>
            <Field
              name="email"
              type="email"
              component={this.renderInput}
              id="emailInput"
              placeholder="Email Address"
              autoFocus
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="firstname"
              type="text"
              component={this.renderInput}
              id="nameInput"
              placeholder="First Name"
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="surname"
              type="text"
              component={this.renderInput}
              id="surnameInput"
              placeholder="Surname"
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="country"
              type="text"
              component={this.renderCountryInput}
              id="countyInput"
              placeholder="Country"
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="phone"
              type="tel"
              component={this.renderInput}
              id="phoneInput"
              placeholder="Mobile Number"
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="password"
              type="password"
              component={this.renderInput}
              id="passwordInput"
              placeholder="Password"
              validateOn="visited"
            />
          </Form.Field>
          <Button
            fluid
            loading={this.props.signingUp}
            primary
            icon
            labelPosition="right"
          >
            Sign Up
            <Icon name="user plus"></Icon>
          </Button>
        </Form>
        {this.renderResult(
          this.props.isSignedUp,
          this.props.signedUpUser,
          this.props.signupErr
        )}
        <div style={{ marginTop: "1em" }}>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.email) {
    //keys of object must match field names
    errors.email = "Please enter your email address";
  }
  if (!formValues.firstname) {
    //keys of object must match field names
    errors.firstname = "Please enter your first name";
  }
  if (!formValues.surname) {
    //keys of object must match field names
    errors.surname = "Please enter your surname";
  }
  if (!formValues.country) {
    //keys of object must match field names
    errors.country = "Please select your county";
  }
  if (!formValues.phone) {
    //keys of object must match field names
    errors.phone = "Please enter your mobile number";
  }
  const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  if (!pattern.test(formValues.password)) {
    errors.password = (
      <ul style={{ textAlign: "left" }}>
        <li>Password must be at least 8 long</li>
        <li>Must contain 1 number</li>
        <li>Must contain 1 uppercase character</li>
      </ul>
    );
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    formActions: state.formActions,
    signingUp: state.auth.signingUp,
    isSignedUp: state.auth.isSignedUp,
    signupErr: state.auth.signupError,
    signedUpUser: state.auth.email,
  };
};

const formWrapped = reduxForm({
  form: "AWSSignUp",
  validate: validate,
})(AWSSignUp);

export default connect(mapStateToProps, {
  signedUp: signedUp,
  signUp: signUp,
  failSignup: failSignup,
})(formWrapped);
