import React from "react";
import SealerList from "../sealers/SealerList";
import NewEstimateItemCreateForm from "./NewEstimateItemCreateForm";
import NewEstimateItemList from "./NewEstimateItemList";
import {
  getSealers,
  clearNewEstimateItems,
  resetSaveSuccess,
  updateEstimate,
  busySaving,
} from "../../actions";
import { connect } from "react-redux";
import "../components.css";
import { isMobile } from "react-device-detect";
import { Accordion, Icon, Label } from "semantic-ui-react";
import { compileDataForSave } from "../../apis/Estimates";

class Update extends React.Component {
  state = { activeIndex: 1 };

  componentDidMount() {
    this.props.getSealers();
    this.props.clearNewEstimateItems();
  }

  executeSave = () => {
    const data = compileDataForSave(
      this.props.estimate.id,
      this.props.estimate.estimatename,
      this.props.estimate.insurername,
      this.props.estimate.regnumber,
      this.props.estimateitems,
      this.props.workUnitsPerHour,
      this.props.userName,
      this.props.userId
    );

    this.props.updateEstimate(data, this.props.estimate.id);
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 3000);
  };

  handleAccordionEvents = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    const bottomHeadClass =
      this.state.activeIndex !== 0 ? { display: "none" } : {};
    const tagText =
      this.state.activeIndex === 0
        ? "Hide Available Sealers"
        : "Add Additional Items";
    return (
      <>
        <Accordion>
          <Accordion.Title
            active={this.state.activeIndex === 0}
            index={0}
            onClick={this.handleAccordionEvents}
          >
            <Icon name="dropdown" />
            <Label color="green" basic>
              {tagText}
            </Label>
          </Accordion.Title>
          <Accordion.Content active={this.state.activeIndex === 0}>
            <div className={className}>
              <SealerList isUpdate />
              <NewEstimateItemCreateForm />
            </div>
          </Accordion.Content>
          <Accordion.Title
            style={bottomHeadClass}
            active={this.state.activeIndex === 0}
            index={0}
            onClick={this.handleAccordionEvents}
          >
            <Icon name="triangle up" />
            <Label color="green" basic>
              {tagText}
            </Label>
          </Accordion.Title>
        </Accordion>
        <NewEstimateItemList
          isUpdate
          estimateId={this.props.estimateId}
          onUpdateClick={this.executeSave}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    estimate: state.estimates.estimate,
    estimateitems: state.newEstimateItems,
    workUnitsPerHour: state.user.settings.setitems.labourUnitsPerHour,
    userName: state.auth.email,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps, {
  getSealers,
  clearNewEstimateItems,
  resetSaveSuccess,
  updateEstimate,
  busySaving,
})(Update);
