import Axios from "axios";
import { env } from "./env";

const Sealers = (id) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;
  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/sealers/${id}`,
      headers: { "x-api-key": key },
    });
  }
  return Axios.create({
    baseURL: `${baseURL}/sealers`,
    headers: { "x-api-key": key },
  });
};

export default Sealers;
