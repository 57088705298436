import React from "react";
import { connect } from "react-redux";
import { Form, Radio, Grid } from "semantic-ui-react";
import {
  busySavingCalcMethod,
  resetSaveSuccessCalcMethod,
} from "../../actions";
import { USE_LABOUR_RATE, USE_WORK_UNITS } from "./profileSettingTypes";

class SetCurrency extends React.Component {
  renderIncompleteMessage() {
    if (!this.props.calculationMethod) {
      return (
        <div className="ui warning message">
          <i className="large red bell icon"></i>
          Please select
        </div>
      );
    }
    return "";
  }

  renderSuccessMessage() {
    if (this.props.successMessage) {
      return (
        <div className="ui positive message">{this.props.successMessage}</div>
      );
    }
    return "";
  }

  renderErrorMessage() {
    if (this.props.errorMessage) {
      return (
        <div className="ui negative message">
          Could not update the calculation method. {this.props.errorMessage}
        </div>
      );
    }
    return "";
  }

  handleChange = (e, { value }) => {
    this.props.busySavingCalcMethod();
    this.props.updateCalculationMethod(value);
    setTimeout(() => {
      this.props.resetSaveSuccessCalcMethod();
    }, 3000);
  };

  render() {
    return (
      <div>
        <Form>
          <Grid stackable columns="2">
            <Grid.Column>
              <Form.Field>
                <Radio
                  name="labourRateRadio"
                  value={USE_LABOUR_RATE}
                  label="Use Labour Rate"
                  onClick={this.handleChange}
                  checked={this.props.calculationMethod === USE_LABOUR_RATE}
                ></Radio>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <Radio
                  name="workUnitsRadio"
                  value={USE_WORK_UNITS}
                  label="Use Work Units"
                  onClick={this.handleChange}
                  checked={this.props.calculationMethod === USE_WORK_UNITS}
                ></Radio>
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form>
        {this.renderIncompleteMessage()}
        {this.renderSuccessMessage()}
        {this.renderErrorMessage()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.api.saveErrorCalcMethod,
    successMessage: state.api.successMessageCalcMethod,
    calculationMethod: state.user.settings.setitems.calculationMethod,
  };
};

export default connect(mapStateToProps, {
  busySavingCalcMethod,
  resetSaveSuccessCalcMethod,
})(SetCurrency);
