import React from "react";
import { connect } from "react-redux";
import { Form, Radio, Grid } from "semantic-ui-react";
import { busySavingCurrency, resetSaveSuccessCurrency } from "../../actions";

class SetCurrency extends React.Component {
  renderIncompleteMessage() {
    if (!this.props.currency.name) {
      return (
        <div className="ui warning message">
          <i className="large red bell icon"></i>
          Please select
        </div>
      );
    }
    return "";
  }

  renderSuccessMessage() {
    if (this.props.successMessage) {
      return (
        <div className="ui positive message">{this.props.successMessage}</div>
      );
    }
    return "";
  }

  renderErrorMessage() {
    if (this.props.errorMessage) {
      return (
        <div className="ui negative message">
          Could not update the currency. {this.props.errorMessage}
        </div>
      );
    }
    return "";
  }

  handleChange = (e, { value }) => {
    this.props.busySavingCurrency();
    this.props.updateCurrency(value);
    setTimeout(() => {
      this.props.resetSaveSuccessCurrency();
    }, 3000);
  };

  render() {
    return (
      <div>
        <Form>
          <Grid stackable columns="3">
            <Grid.Column>
              <Form.Field>
                <Radio
                  name="currencyRadio"
                  value="ZAR"
                  label="ZAR"
                  onChange={this.handleChange}
                  checked={this.props.currency.name === "ZAR"}
                ></Radio>
              </Form.Field>
              <Form.Field>
                <Radio
                  name="currencyRadio"
                  value="USD"
                  label="USD"
                  onChange={this.handleChange}
                  checked={this.props.currency.name === "USD"}
                ></Radio>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <Radio
                  name="currencyRadio"
                  value="EUR"
                  label="EUR"
                  onChange={this.handleChange}
                  checked={this.props.currency.name === "EUR"}
                ></Radio>
              </Form.Field>
              <Form.Field>
                <Radio
                  name="currencyRadio"
                  value="GBP"
                  label="GBP"
                  onChange={this.handleChange}
                  checked={this.props.currency.name === "GBP"}
                ></Radio>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <Radio
                  name="currencyRadio"
                  value="AUD"
                  label="AUD"
                  onChange={this.handleChange}
                  checked={this.props.currency.name === "AUD"}
                ></Radio>
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form>
        {this.renderIncompleteMessage()}
        {this.renderSuccessMessage()}
        {this.renderErrorMessage()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    successMessage: state.api.successMessageCurrency,
    errorMessage: state.api.saveErrorCurrency,
    currency: state.user.settings.setitems.currency,
  };
};

export default connect(mapStateToProps, {
  busySavingCurrency,
  resetSaveSuccessCurrency,
})(SetCurrency);
