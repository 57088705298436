import {
  GET_ESTIMATES,
  SELECT_ESTIMATE,
  SET_ESTIMATE_SEARCH_RANGE,
  SET_LAST_ESTIMATE_KEY,
  CLEAR_ESTIMATE,
  GET_ESTIMATE,
  ARCHIVE_ESTIMATE,
  SELECT_ESTIMATE_ITEM,
} from "../actions/types";
import _ from "lodash";

const INITIAL_STATE = {
  estimates: [],
  estimate: {},
  lastEstimateKey: {},
  selectedItem: {},
};

const estimateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ESTIMATES:
      return {
        ...state,
        estimates: action.payload,
      };
    case GET_ESTIMATE:
      return {
        ...state,
        estimate: action.payload,
      };
    case CLEAR_ESTIMATE:
      return { ...state, estimate: {} };
    case SELECT_ESTIMATE:
      return {
        ...state,
        selectedId: action.payload.estimateId,
        selectedName: action.payload.estimateName,
      };
    case SELECT_ESTIMATE_ITEM:
      return { ...state, selectedItem: action.payload };
    case SET_ESTIMATE_SEARCH_RANGE:
      return { ...state, searchRange: action.payload };
    case SET_LAST_ESTIMATE_KEY:
      return { ...state, lastEstimateKey: action.payload };
    case ARCHIVE_ESTIMATE:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};

export default estimateReducer;
