import { isArray, isObject } from "lodash";
import React from "react";

class pdfHelper extends React.Component {
  constructor(
    props,
    {
      docFont,
      h1Size,
      h1Color,
      h1Weight,
      h2Size,
      h2Color,
      h2Weight,
      headIndent,
      textSize,
      textColor,
      textWeight,
      textIndent,
      textLineHeight,
      yStart,
      tabIndent,
      tab2Indent,
      tab3Indent,
      tab4Indent,
      borderSize,
      bottomMargin,
    }
  ) {
    super(props);
    this.childIndent = 10;
    this.headingTopMargin = 10;
    this.bottomMargin = bottomMargin ? bottomMargin : 10;
    this.pageWidth = 850;
    this.docFont = docFont ? docFont : "Helvetica";
    this.head1Size = h1Size ? h1Size : 22;
    this.head1Color = h1Color ? h1Color : "black";
    this.head1Weight = h1Weight ? h1Weight : "normal";
    this.head1Indent = headIndent ? headIndent : 30;
    this.head2Size = h2Size ? h1Size : 14;
    this.head2Color = h2Color ? h1Color : "black";
    this.head2Weight = h2Weight ? h1Weight : "normal";
    this.head2Indent = this.head1Indent + this.childIndent;
    this.textSize = textSize ? textSize : 12;
    this.textColor = textColor ? textColor : "black";
    this.textWeight = textWeight ? textWeight : "normal";
    this.textIndent = textIndent ? textIndent : 25;
    this.textLineHeight = textLineHeight ? textLineHeight : 1;
    this.yStart = yStart ? yStart : 20;
    this.yNext = yStart ? yStart : 20;
    this.tab1Indent = tabIndent ? tabIndent : 200;
    this.tab2Indent = tab2Indent ? tabIndent : 300;
    this.tab3Indent = tab3Indent ? tabIndent : 400;
    this.tab4Indent = tab4Indent ? tabIndent : 500;
    this.borderSize = borderSize ? borderSize : 10;
  }

  textTypes = {
    h1: "h1",
    h2: "h2",
    text: "text",
    image: "image",
    verticalline: "verticalline",
  };

  setYSameLine = ({ textType, arrayCount, sameLine, imageHeight, lineLen }) => {
    //if we want to print text on same line as previous we need to decrease the y-offset again
    let offset = 0;
    switch (textType) {
      case this.textTypes.h1:
        offset = sameLine
          ? this.head1Size * this.textLineHeight + this.headingTopMargin
          : 0;
        if (sameLine) {
          this.yNext -= offset;
        }
        return offset;
      case this.textTypes.h2:
        offset = sameLine
          ? this.head2Size * this.textLineHeight + this.headingTopMargin
          : 0;
        if (sameLine) {
          this.yNext -= offset;
        }
        return offset;
      case this.textTypes.text:
        offset = sameLine
          ? this.textSize * this.textLineHeight * arrayCount
          : 0;
        if (sameLine) {
          this.yNext -= offset;
        }
        return offset;
      case this.textTypes.image:
        offset = sameLine
          ? imageHeight + this.textSize * this.textLineHeight
          : 0;
        if (sameLine) {
          this.yNext -= offset;
        }
        return offset;
      case this.textTypes.verticalline:
        offset = sameLine
          ? lineLen / 2 + this.textSize * this.textLineHeight
          : 0;
        if (sameLine) {
          this.yNext -= offset;
        }
        return offset;
      default:
        return offset;
    }
  };

  setYPosition = ({ textType, arrayCount, imageHeight, lineLen }) => {
    //if we want to print text on same line as previous we need to decrease the y-offset again
    switch (textType) {
      case this.textTypes.h1:
        this.yNext +=
          this.head1Size * this.textLineHeight + this.headingTopMargin;
        break;
      case this.textTypes.h2:
        this.yNext +=
          this.head2Size * this.textLineHeight + this.headingTopMargin;
        break;
      case this.textTypes.text:
        this.yNext += this.textSize * this.textLineHeight * arrayCount;
        break;
      case this.textTypes.image:
        this.yNext += imageHeight + this.textSize * this.textLineHeight;
        break;
      case this.textTypes.verticalline:
        this.yNext += lineLen / 2 + this.textSize * this.textLineHeight;
        break;
      default:
        return;
    }
  };

  setTextIndent = (indent) => {
    switch (indent) {
      case 1:
        return this.head1Indent + this.childIndent;
      case 2:
        return this.head2Indent + this.childIndent;
      case 3:
        return this.tab1Indent;
      case 4:
        return this.tab2Indent;
      case 5:
        return this.tab3Indent;
      case 6:
        return this.tab4Indent;
      //custom indent
      default:
        return indent;
    }
  };

  checkNewPage = (doc, lineItemHeight, offset) => {
    const pageHeight = doc.internal.pageSize.height;
    if (
      this.yNext + this.bottomMargin + lineItemHeight + offset >=
      pageHeight
    ) {
      doc.addPage();
      this.yNext = this.yStart; // Restart height position
    }
    this.border(doc);
  };

  heading1 = (doc, text) => {
    this.checkNewPage(doc, this.head1Size);
    doc.setFont(this.docFont, this.head1Weight);
    doc.setFontSize(this.head1Size);
    doc.setTextColor(this.head1Color);
    const yPos = this.yNext;
    this.setYPosition({ textType: this.textTypes.h1, arrayCount: 1 });
    return doc.text(text, this.head1Indent, yPos);
  };

  border = (doc) => {
    doc.setDrawColor("gray");
    return doc.rect(
      this.borderSize,
      this.borderSize,
      doc.internal.pageSize.width - 2 * this.borderSize,
      doc.internal.pageSize.height - 2 * this.borderSize
    );
  };

  heading2 = (doc, text) => {
    this.checkNewPage(doc, this.head2Size, 0);
    doc.setFont(this.docFont, this.head2Weight);
    doc.setFontSize(this.head2Size);
    doc.setTextColor(this.head2Color);
    const yPos = this.yNext + this.headingTopMargin;
    this.setYPosition({ textType: this.textTypes.h2, arrayCount: 1 });
    return doc.text(text, this.head2Indent, yPos);
  };

  text = (doc, text, indent, sameLine, headStyle, offset) => {
    const _offset = offset ? offset : 0;
    this.checkNewPage(
      doc,
      headStyle ? this.head1Size : this.textLineHeight,
      _offset
    );
    let cnt = 1;
    if (isArray(text)) {
      cnt = text.length;
    }
    if (headStyle === "h1") {
      doc.setFont(this.docFont, this.head1Weight);
      doc.setFontSize(this.head1Size);
      doc.setTextColor(this.head1Color);
    } else if (headStyle === "h2") {
      doc.setFont(this.docFont, this.head2Weight);
      doc.setFontSize(this.head2Size);
      doc.setTextColor(this.head2Color);
    } else {
      doc.setLineHeightFactor(this.textLineHeight);
      doc.setFont(this.docFont, this.textWeight);
      doc.setFontSize(this.textSize + 1);
      doc.setTextColor(this.textColor);
    }
    const yPos =
      this.yNext -
      this.setYSameLine({
        textType: this.textTypes.text,
        arrayCount: cnt,
        sameLine,
      }) +
      _offset;
    this.setYPosition({
      textType: this.textTypes.text,
      arrayCount: cnt,
    });
    return doc.text(text, this.setTextIndent(indent), yPos);
  };

  line = (doc, indent, thickness, color, offset) => {
    const _offset = offset ? offset : 0;
    this.checkNewPage(doc, thickness, _offset);
    const yPos = this.yNext;
    const ident = this.setTextIndent(indent);
    doc.setDrawColor(color);
    const yadjust = this.textSize / 2;
    this.yNext += yadjust * this.textLineHeight + thickness;
    return doc.line(
      ident,
      yPos - yadjust,
      this.pageWidth - ident,
      yPos - yadjust + thickness
    );
  };

  shortline = (doc, indent, thickness, color, length, offset) => {
    this.checkNewPage(doc, thickness, offset);
    const yPos = this.yNext + offset;
    const ident = this.setTextIndent(indent);
    doc.setDrawColor(color);
    doc.setLineWidth(thickness);
    return doc.line(ident, yPos, ident + length, yPos);
  };

  shortVerticalLine = (
    doc,
    indent,
    thickness,
    color,
    length,
    sameLine,
    offset
  ) => {
    const _offset = offset ? offset : 0;
    this.checkNewPage(doc, length, _offset);
    const yPos =
      this.yNext -
      this.setYSameLine({
        textType: this.textTypes.verticalline,
        sameLine,
        lineLen: length,
      }) +
      _offset;
    this.setYPosition({
      textType: this.textTypes.verticalline,
      lineLen: length,
    });
    const ident = this.setTextIndent(indent);
    doc.setDrawColor(color);
    doc.setLineWidth(thickness);
    return doc.line(ident, yPos, ident, yPos + length);
  };

  imageBorder(doc, border, coordinates) {
    const { x, y, w, h } = coordinates;
    doc.setLineWidth(border.width);
    doc.setDrawColor(border.color);
    return doc.rect(
      x - border.padding,
      y - border.padding,
      w + border.padding * 2,
      h + border.padding * 2
    );
  }

  rectange(doc, x, y, w, h, color) {
    doc.setDrawColor(color);
    doc.setLineWidth(1);
    doc.rect(x, y, w, h);
  }

  image = (doc, img, indent, w, h, sameLine, border) => {
    this.checkNewPage(doc, h, 0);
    doc.setFont(this.docFont, this.head2Weight);
    doc.setFontSize(this.head2Size);
    doc.setTextColor(this.head2Color);
    const yPos =
      this.yNext -
      this.setYSameLine({
        textType: this.textTypes.image,
        sameLine,
        imageHeight: h,
      });
    this.setYPosition({
      textType: this.textTypes.image,
      arrayCount: 1,
      imageHeight: h,
    });
    if (border) {
      let coordinates = {};
      coordinates.x = this.setTextIndent(indent);
      coordinates.y = yPos;
      coordinates.w = w;
      coordinates.h = h;
      this.imageBorder(doc, border, coordinates);
    }
    return doc.addImage(
      img,
      "JPEG",
      this.setTextIndent(indent),
      yPos,
      w,
      h,
      null,
      "FAST",
      0
    );
  };
}

export default pdfHelper;
