import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Input, Button, Icon, Form, Grid, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";

class SaveModal extends React.Component {
  state = { insurer: "" };

  renderInput = ({
    input,
    type,
    meta,
    placeholder,
    id,
    autoFocus,
    icon,
    iconPosition,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <Input
          icon={icon}
          iconPosition={iconPosition}
          type={type}
          fluid
          {...input}
          id={id}
          placeholder={placeholder}
          autoFocus={autoFocus}
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderError = ({ error, submitFailed }) => {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  };

  saveData = (formValues) => {
    const estimateName =
      //remove all commas from estimate name for CSV export not to "misalign"
      formValues.name ? formValues.name.replace(",", "") : formValues.name;
    const registration = formValues.registration
      ? formValues.registration.replace(",", "")
      : formValues.registration;
    this.props.onSubmitClick(estimateName, this.state.insurer, registration);
  };

  renderResult = () => {
    if (this.props.errResult) {
      return <div className="ui error message">{this.props.errResult}</div>;
    } else if (this.props.saveSuccess) {
      return (
        <div className="ui success message">
          Your quote was successfully saved.{" "}
        </div>
      );
    }
  };

  renderCancelText = () => {
    if (this.props.saveSuccess) {
      return "Done";
    } else {
      return "Cancel";
    }
  };

  onDropdownChange = (e, { value }) => {
    this.setState({ insurer: value });
  };

  renderDropDown = ({ style, defaultValue }) => {
    const insurerOptions = [
      { key: "abacus", text: "Abacus", value: "Abacus" },
      { key: "absa", text: "Absa", value: "Absa" },
      { key: "aig", text: "AIG", value: "AIG" },
      { key: "allianz", text: "Allianz", value: "Allianz" },
      { key: "aurora", text: "Aurora", value: "Aurora" },
      {
        key: "auto and general",
        text: "Auto And General",
        value: "Auto And General",
      },
      { key: "bidvest", text: "Bidvest", value: "Bidvest" },
      { key: "bryte", text: "Bryte", value: "Bryte" },
      { key: "budget", text: "Budget", value: "Budget" },
      { key: "cfao motors", text: "CFAO Motors", value: "CFAO Motors" },
      { key: "chubb", text: "Chubb", value: "Chubb" },
      {
        key: "clientele general",
        text: "Clientele General",
        value: "Clientele General",
      },
      {
        key: "coface south africa",
        text: "Coface South Africa",
        value: "Coface South Africa",
      },
      { key: "compass", text: "Compass", value: "Compass" },
      { key: "constantia", text: "Constantia", value: "Constantia" },
      {
        key: "corporate guarantee ",
        text: "Corporate Guarantee ",
        value: "Corporate Guarantee ",
      },
      {
        key: "credit guarantee",
        text: "Credit Guarantee",
        value: "Credit Guarantee",
      },
      { key: "dial direct", text: "Dial Direct", value: "Dial Direct" },
      {
        key: "discovery insure",
        text: "Discovery Insure",
        value: "Discovery Insure",
      },
      { key: "dotsure", text: "Dotsure", value: "Dotsure" },
      { key: "export credit", text: "Export Credit", value: "Export Credit" },
      {
        key: "first for women",
        text: "First For Women",
        value: "First For Women",
      },
      {
        key: "firstrand short term",
        text: "Firstrand Short Term",
        value: "Firstrand Short Term",
      },
      { key: "g4s", text: "G4S", value: "G4S" },
      { key: "genric", text: "Genric", value: "Genric" },
      { key: "hdi global", text: "HDI Global", value: "HDI Global" },
      { key: "hollard", text: "Hollard", value: "Hollard" },
      {
        key: "indequity specialised",
        text: "Indequity Specialised",
        value: "Indequity Specialised",
      },
      { key: "infiniti", text: "Infiniti", value: "Infiniti" },
      {
        key: "khula credit guarantee",
        text: "Khula Credit Guarantee",
        value: "Khula Credit Guarantee",
      },
      { key: "king price", text: "King Price", value: "King Price" },
      { key: "land bank", text: "Land Bank", value: "Land Bank" },
      {
        key: "legal expenses",
        text: "Legal Expenses",
        value: "Legal Expenses",
      },
      {
        key: "legal practitioners indemnity",
        text: "Legal Practitioners Indemnity",
        value: "Legal Practitioners Indemnity",
      },
      {
        key: "lion of africa",
        text: "Lion Of Africa",
        value: "Lion Of Africa",
      },
      { key: "lloyds", text: "Lloyds", value: "Lloyds" },
      { key: "lombard", text: "Lombard", value: "Lombard" },
      { key: "miway", text: "Miway", value: "Miway" },
      { key: "momentum", text: "Momentum", value: "Momentum" },
      { key: "nedgroup", text: "Nedgroup", value: "Nedgroup" },
      { key: "new national", text: "New National", value: "New National" },
      { key: "nzalo", text: "Nzalo", value: "Nzalo" },
      {
        key: "old mutual insure ",
        text: "Old Mutual Insure ",
        value: "Old Mutual Insure ",
      },
      { key: "outsurance", text: "Outsurance", value: "Outsurance" },
      {
        key: "professional provident society short-term",
        text: "Professional Provident Society Short-Term",
        value: "Professional Provident Society Short-Term",
      },
      {
        key: "rand mutual assurance",
        text: "Rand Mutual Assurance",
        value: "Rand Mutual Assurance",
      },
      { key: "renasa", text: "Renasa", value: "Renasa" },
      { key: "safire", text: "Safire", value: "Safire" },
      {
        key: "s.a. home loans",
        text: "S.A. Home Loans",
        value: "S.A. Home Loans",
      },
      { key: "santam", text: "Santam", value: "Santam" },
      {
        key: "santam structured",
        text: "Santam Structured",
        value: "Santam Structured",
      },
      { key: "shoprite", text: "Shoprite", value: "Shoprite" },
      { key: "standard", text: "Standard", value: "Standard" },
      { key: "vodacom", text: "Vodacom", value: "Vodacom" },
      {
        key: "western national",
        text: "Western National",
        value: "Western National",
      },
      { key: "workers life", text: "Workers Life", value: "Workers Life" },
    ];

    return (
      <Dropdown
        style={style}
        defaultValue={defaultValue}
        fluid
        search
        selection
        placeholder="Insurer (Optional)"
        options={insurerOptions}
        onChange={this.onDropdownChange}
      />
    );
  };

  render() {
    const shadeStyle = {
      marginBottom: "2em",
    };
    return ReactDOM.createPortal(
      <div className="ui dimmer modals visible active">
        <div className="ui standard modal visible active">
          <div className="header">{this.props.title}</div>
          <Grid stackable padded>
            <Grid.Column>
              <Form onSubmit={this.props.handleSubmit(this.saveData)}>
                <Form.Field>
                  <Field
                    name="name"
                    type="text"
                    id="estimateName"
                    placeholder="Quote name e.g. job card number"
                    component={this.renderInput}
                    autoFocus
                    icon="small asterisk"
                    iconPosition="left"
                  ></Field>
                </Form.Field>
                <Form.Field>
                  <Field
                    name="registration"
                    type="text"
                    id="registration"
                    placeholder="Vehicle registration (Optional)"
                    component={this.renderInput}
                  ></Field>
                </Form.Field>
                <Form.Field>
                  <Field
                    style={shadeStyle}
                    name="insurer"
                    component={this.renderDropDown}
                    id="insurer"
                  />
                </Form.Field>
                <Icon name="small asterisk" /> = Required
                <Button.Group className="ui right floated">
                  <Button
                    loading={this.props.busySaving}
                    primary
                    icon
                    labelPosition="right"
                  >
                    Save
                    <Icon name="user plus"></Icon>
                  </Button>
                  <Button.Or />
                  <Button type="button" onClick={this.props.onCancelClick}>
                    {this.renderCancelText()}
                  </Button>
                </Button.Group>
              </Form>
            </Grid.Column>
          </Grid>
          <div className="content">{this.renderResult()}</div>
        </div>
      </div>,
      document.querySelector("#modal")
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.name) {
    //keys of object must match field names
    errors.name = "Please enter name for the Quote e.g. job card number";
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "SaveModal",
  validate: validate,
})(SaveModal);

const mapStateToProps = (state) => {
  return {
    busySaving: state.api.busySaving,
    saveSuccess: state.api.saveSuccess,
    errResult: state.api.saveError,
  };
};

export default connect(mapStateToProps, {})(formWrapped);
