import React from "react";
import SealerList from "../sealers/SealerList";
import NewEstimateItemCreateForm from "./NewEstimateItemCreateForm";
import NewEstimateItemList from "./NewEstimateItemList";
import { getSealers, clearNewEstimateItems } from "../../actions";
import { connect } from "react-redux";
import "../components.css";
import { isMobile } from "react-device-detect";

class Create extends React.Component {
  componentDidMount() {
    this.props.getSealers();
    this.props.clearNewEstimateItems();
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <div>
        <div className={className}>
          <SealerList />
          <NewEstimateItemCreateForm />
        </div>
        <NewEstimateItemList />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedItem: state.estimates.selectedItem,
  };
};

export default connect(mapStateToProps, { getSealers, clearNewEstimateItems })(
  Create
);
