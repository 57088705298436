import React from "react";

class utils extends React.Component {
  constructor(props) {
    super(props);
    this.index = 0;
  }

  nextNumber = () => {
    this.index = this.index + 1;
    return this.index;
  };

  dateToLongString = (date) => {
    const month = [];
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";
    return (
      date.getDate() + " " + month[date.getMonth()] + " " + date.getFullYear()
    );
  };

  dateToString = (date) => {
    return date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();
  };

  dateToDateTimeString = (date) => {
    const getTwoDigitDatePart = (datePart) => {
      if (datePart < 10) {
        return `0${datePart}`;
      }
      return datePart;
    };

    return (
      getTwoDigitDatePart(date.getDate()) +
      "-" +
      getTwoDigitDatePart(date.getMonth() + 1) +
      "-" +
      date.getFullYear() +
      " " +
      getTwoDigitDatePart(date.getHours()) +
      ":" +
      getTwoDigitDatePart(date.getMinutes())
    );
  };
}

export default utils;
