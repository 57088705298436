import { GET_USER_SETTINGS, CREATE_USER_SETTINGS } from "../actions/types";

const INITIAL_STATE = {
  settings: { setitems: { currency: {} } },
};

const userSettingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_SETTINGS:
      return { ...state, settings: action.payload };
    case CREATE_USER_SETTINGS:
      return { ...state, settings: action.payload };
    default:
      return state;
  }
};

export default userSettingsReducer;
