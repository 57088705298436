import {
  SIGN_IN,
  SIGN_OUT,
  SIGNED_UP,
  SIGN_UP,
  FAIL_SIGNUP,
  FAIL_SIGN_IN,
  SET_USER_FIRSTNAME,
  SET_RESET_USERNAME,
  RESET_SIGN_IN,
} from "../actions/types";

const INITIAL_STATE = {
  isSignedIn: false,
  userId: null,
  isSignedUp: null,
  signupError: null,
  signingUp: null,
  signinError: null,
  authorizor: null,
  updatingPassword: null,
  passwordUpdateError: null,
  passwordUpdateSuccessMessage: null,
  isPasswordUpdated: null,
  currency: null,
  updatingCurrency: null,
  firstName: null,
  user: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        signinError: null,
        userId: action.payload.userId,
        email: action.payload.email,
        authorizor: action.payload.authorizor,
        user: action.payload.user,
      };
    case SET_USER_FIRSTNAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case SIGN_OUT:
      return {
        ...state,
        isSignedIn: false,
        authorizor: null,
        email: null,
        userId: null,
        firstName: null,
        user: null,
      };
    case SIGN_UP:
      return {
        ...state,
        isSignedUp: false,
        signingUp: true,
        signupError: null,
        email: null,
      };
    case SIGNED_UP:
      return {
        ...state,
        isSignedUp: true,
        signingUp: false,
        email: action.payload,
      };
    case FAIL_SIGNUP:
      return {
        ...state,
        isSignedUp: false,
        signingUp: false,
        signupError: action.payload,
      };
    case FAIL_SIGN_IN:
      return {
        ...state,
        isSignedIn: false,
        signinError: action.payload,
      };
    case SET_RESET_USERNAME:
      return {
        ...state,
        passwordResetUsername: action.payload,
      };
    case RESET_SIGN_IN:
      return {
        ...state,
        isSignedIn: false,
        signinError: null,
      };
    default:
      return state;
  }
};

export default authReducer;
