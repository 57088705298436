import _ from "lodash";
import {
  CREATE_ESTIMATE_ITEM,
  DELETE_ESTIMATE_ITEM,
  CLEAR_NEW_ESTIMATE_ITEMS,
  LOAD_ESTIMATE_ITEM_FOR_EDIT,
  UPDATE_ESTIMATE_ITEM,
} from "../actions/types";

const estimateCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ESTIMATE_ITEM:
      return { ...state, [action.payload.recordId]: action.payload };
    case DELETE_ESTIMATE_ITEM:
      return _.omit(state, action.payload);
    case CLEAR_NEW_ESTIMATE_ITEMS:
      return {};
    case LOAD_ESTIMATE_ITEM_FOR_EDIT:
      return { ...state, [action.payload.recordId]: action.payload };
    case UPDATE_ESTIMATE_ITEM:
      return { ...state, [action.payload.recordId]: action.payload };
    default:
      return state;
  }
};

export default estimateCreateReducer;
