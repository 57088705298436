import React from "react";
import Menu from "../components/Menu";
import UpdateProfile from "../components/userProfile/UpdateProfile";
import { SETTINGS } from "../components/menuItemTypes";

const updateProfileView = () => {
  return (
    <>
      <Menu contentView={<UpdateProfile />} renderMenuFor={SETTINGS} />
    </>
  );
};

export default updateProfileView;
