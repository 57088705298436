import { combineReducers } from "redux";
import sealersReducer from "./sealersReducer";
import estimateCreateReducer from "./estimateCreateReducer";
import selectedSealerReducer from "./selectedSealerReducer";
import { reducer as formReducer } from "redux-form";
import estimateItemCreateFormReducer from "./estimateItemCreateFormReducer";
import authReducer from "./authReducer";
import ApiReducer from "./ApiReducer";
import estimateReducer from "./estimateReducer";
import userSettingsReducer from "./userSettingsReducer";

export default combineReducers({
  sealers: sealersReducer,
  newEstimateItems: estimateCreateReducer,
  estimates: estimateReducer,
  api: ApiReducer,
  selectedSealer: selectedSealerReducer,
  estimateFormActions: estimateItemCreateFormReducer,
  form: formReducer,
  auth: authReducer,
  user: userSettingsReducer,
});
