import Axios from "axios";
import { env } from "./env";

const SealerCost = ({ id, length, area, rate, shade, panels }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;
  return Axios.create({
    baseURL: `${baseURL}/sealers/cost/${id}`,
    headers: { "x-api-key": key },
    params: {
      len: length,
      rate: rate,
      area: area,
      shade: shade,
      panels: panels,
    },
  });
};

export default SealerCost;
