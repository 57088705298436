import Axios from "axios";
import { env } from "./env";

const UserSettings = (email) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;
  if (email) {
    return Axios.create({
      baseURL: `${baseURL}/usersettings/${email}`,
      headers: { "x-api-key": key },
    });
  }
  return Axios.create({
    baseURL: `${baseURL}/usersettings`,
    headers: { "x-api-key": key },
  });
};

export default UserSettings;
