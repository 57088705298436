import { React, useState, useEffect } from "react";
import { Dropdown, Input, Label, Icon } from "semantic-ui-react";
import "../components.css";

const SearchBar = ({ onDropdownSelect, onSearchTermChange }) => {
  const [range, setRange] = useState("");
  const [term, setTerm] = useState("");

  useEffect(() => {
    const timeId = setTimeout(() => {
      onSearchTermChange(term, range);
    }, 800);

    return () => {
      clearTimeout(timeId); 
    };
  }, [term]);

  const handleChange = (event, data) => {
    if (data) {
      setRange(data.text);
      setTerm("");
      onDropdownSelect(data.value);
    }
  };

  const renderFilterTag = () => {
    if (range) {
      return (
        <Label style={rightFloated} className="ui tag label">
          {range}
          <Icon
            onClick={(e) => handleChange(e, { text: null, value: null })}
            color="red"
            size="large"
            name="delete"
          />
        </Label>
      );
    }
  };

  const timeOptions = [
    {
      key: "yday",
      text: "Yesterday",
      value: "yday=1",
      label: { color: "red", empty: true, circular: true },
    },
    {
      key: "sevendays",
      text: "Last 7 days",
      value: "days=7",
      label: { color: "black", empty: true, circular: true },
    },
    {
      key: "fourteendays",
      text: "Last 14 days",
      value: "days=14",
      label: { color: "purple", empty: true, circular: true },
    },
    {
      key: "thirtydays",
      text: "Last 30 days",
      value: "days=30",
      label: { color: "orange", empty: true, circular: true },
    },
    {
      key: "pweek",
      text: "Previous Week",
      value: "pweek=1",
      label: { color: "blue", empty: true, circular: true },
    },
    {
      key: "pmonth",
      text: "Previous Month",
      value: "pmonth=1",
      label: { color: "green", empty: true, circular: true },
    },
  ];
  const dropdownStyle = {
    marginBottom: "20px",
    float: "right",
    fontSize: "90%",
    fontWeight: "lighter",
  };

  const menuStyle = {
    borderStyle: "none",
    boxShadow: "none",
  };

  const rightFloated = {
    float: "right",
    marginBottom: "0.5em",
  };

  return (
    <>
      <div className="eight wide column">
        <Input
          action={{
            icon: { name: "delete", color: "red" },
            onClick: () => setTerm(""),
          }}
          fluid
          placeholder="Search..."
          icon="search"
          iconPosition="left"
          className="ui-search"
          onChange={(e) => setTerm(e.target.value)}
          value={term}
        />
      </div>
      <div className="eight wide column">
        {renderFilterTag()}
        <Dropdown
          text="Created"
          icon="filter"
          pointing="top right"
          labeled
          button
          className="ui green icon"
          style={dropdownStyle}
        >
          <Dropdown.Menu>
            <Dropdown.Header icon="calendar" content="Create Date" />
            <Dropdown.Menu style={menuStyle} scrolling>
              {timeOptions.map((option) => (
                <Dropdown.Item
                  key={option.value}
                  {...option}
                  onClick={handleChange}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default SearchBar;
