import React from "react";
import "../components.css";
import utils from "../../utilities";
import ActionsMenu from "./ActionsMenu";
import { PDF, DELETE, EMAIL, EDIT } from "../../actions/types";

const EstimateList = ({
  estimate,
  onEstimateClick,
  onActionSelect,
  itemNumber,
}) => {
  const onItemActionSelect = (action) => {
    onActionSelect(action, estimate);
  };

  const renderCost = () => {
    if (estimate.cost) {
      return (
        <span
          data-tooltip="Total Cost"
          className="content"
          style={{ paddingTop: "1%" }}
        >
          <span>
            <i
              className="large money bill alternate icon"
              style={{ paddingLeft: "0%" }}
            ></i>
          </span>
          <span style={{ paddingLeft: "2%" }}>{estimate.cost}</span>
        </span>
      );
    }
  };

  const renderMaterials = () => {
    return (
      <span
        data-tooltip="Material Cost"
        className="content"
        style={{ paddingTop: "1%" }}
      >
        <span style={{ paddingLeft: "0%" }}>
          <i className="large paint brush icon"></i>
        </span>
        <span>{estimate.totalmaterial.toFixed(2)}</span>
      </span>
    );
  };

  const renderRegNo = () => {
    return (
      <span
        data-tooltip="Vehicle Registration"
        className="content"
        style={{ paddingTop: "0%" }}
      >
        {estimate.regnumber ? (
          <span>
            <i className="large car icon"></i>
          </span>
        ) : null}
        <span>{estimate.regnumber}</span>
      </span>
    );
  };

  const renderInsurer = () => {
    return (
      <span
        data-tooltip="Insurer"
        className="content"
        style={{ paddingTop: "0%" }}
      >
        {estimate.insurername ? (
          <span style={{ paddingLeft: "0%" }}>
            <i className="large shield icon"></i>
          </span>
        ) : null}
        <span>{estimate.insurername}</span>
      </span>
    );
  };

  const renderLabour = () => {
    let labourCostTime = "";
    let tipText = "";
    if (estimate.totallabourunits) {
      labourCostTime = estimate.totallabourunits;
      tipText = "Labour Units";
    } else if (estimate.totallabourcost) {
      labourCostTime = estimate.totallabourcost;
      tipText = "Labour Cost";
    } else {
      labourCostTime = 0.0;
    }
    return (
      <span
        data-tooltip={tipText}
        style={{ paddingTop: "1%" }}
        className="description"
      >
        <span>
          <i className="large stopwatch icon"></i>
        </span>
        <span>{labourCostTime.toFixed(2)}</span>
      </span>
    );
  };

  const u = new utils();

  const Actions = [
    {
      key: EDIT,
      text: "Edit",
      value: EDIT,
      icon: { name: "edit", color: "green" },
    },
    {
      key: DELETE,
      text: "Delete",
      value: DELETE,

      icon: { name: "delete", color: "red" },
    },
    {
      key: PDF,
      text: "Save to PDF",
      value: PDF,
      icon: { name: "file pdf", color: "red" },
    },
    {
      key: EMAIL,
      text: "Share via Email",
      value: EMAIL,
      icon: { name: "mail", color: "blue" },
    },
  ];

  return (
    <>
      <div
        style={{ fontFamily: "Raleway" }}
        className="ui stackable three column grid"
        key={estimate.estimateId}
      >
        <div className="four wide column">
          <div
            style={{ cursor: "pointer", fontWeight: "bold" }}
            className="header"
            onClick={() =>
              onEstimateClick({
                estimateId: estimate.id,
                estimateName: estimate.estimatename,
              })
            }
          >
            <span style={{ fontSize: "1.5em", color: "blue" }}>
              {itemNumber}
            </span>
            <span style={{ marginLeft: "1.5em" }}>{estimate.estimatename}</span>
          </div>
          <div style={{ paddingTop: "1.5em", fontWeight: "normal" }}>
            {renderRegNo()}
          </div>
        </div>
        <div className="three wide column">
          <div className="content">
            <div
              data-tooltip="Create Date"
              style={{ paddingTop: "1%" }}
              className="description"
            >
              <span>
                <i className="large calendar icon"></i>
              </span>
              <span style={{ paddingLeft: "1%" }}>
                {u.dateToDateTimeString(new Date(estimate.createdat))}
              </span>
            </div>
            <div
              style={{
                paddingTop: "1.5em",
                textAlign: "left",
                fontWeight: "normal",
              }}
            >
              {renderInsurer()}
            </div>
          </div>
        </div>
        <div className="two wide column">{renderLabour()}</div>
        <div className="two wide column">{renderMaterials()}</div>
        <div className="two wide column">{renderCost()}</div>
        <div className="three wide column">
          <span className="content" data-tooltip="View Items">
            <span
              onClick={() =>
                onEstimateClick({
                  estimateId: estimate.id,
                  estimateName: estimate.estimatename,
                })
              }
              className="ui labeled button"
              tabIndex="0"
            >
              <span className="ui tiny basic blue icon button">
                <i className="eye icon"></i>
              </span>

              <span
                style={{ fontSize: "80%", fontWeight: "lighter" }}
                className="ui green left pointing label"
              >
                {estimate.itemcount}
              </span>
            </span>
          </span>
          <span>
            <ActionsMenu
              actions={Actions}
              icon="ellipsis horizontal"
              className="ui basic tiny green icon button"
              onDropdownSelect={onItemActionSelect}
            />
          </span>
        </div>
      </div>

      <div className="ui divider"></div>
    </>
  );
};

export default EstimateList;
