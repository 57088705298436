import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, clearFields, change } from "redux-form";
import {
  createEstimateItem,
  getUserSettings,
  setPaintShade,
} from "../../actions";
import { Dropdown } from "semantic-ui-react";
import "../components.css";
import { USE_LABOUR_RATE } from "../userProfile/profileSettingTypes";
import { isMobile } from "react-device-detect";

class NewEstimateItemCreate extends React.Component {
  state = { panels: null };

  componentDidMount() {
    this.props.getUserSettings(this.props.email);
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  /*Field passes store props to field, we can destructure out input prop and 
    {...input } hooks up all store props to field props*/
  renderInput = ({ input, text, label, meta, placeholder, id, autoFocus }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="ui right labeled input">
          <input
            {...input}
            id={id}
            placeholder={placeholder}
            autoComplete="off"
            autoFocus={autoFocus}
          />
          <div className="ui green basic label">{label}</div>
        </div>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onDropdownChange = (e, { value }) => {
    this.props.setPaintShade(value);
  };

  renderDropDownLight = ({ placeholder, value, style, shadeOptions }) => {
    console.log(value);

    return (
      <Dropdown
        style={style}
        placeholder={placeholder}
        fluid
        value={value}
        selection
        options={shadeOptions}
        onChange={this.onDropdownChange}
      />
    );
  };

  renderDropDownHeavy = ({ placeholder, value, style, shadeOptions }) => {
    console.log(value);

    return (
      <Dropdown
        style={style}
        placeholder={placeholder}
        fluid
        value={value}
        selection
        options={shadeOptions}
        onChange={this.onDropdownChange}
      />
    );
  };

  onQtyDropdownChange = (e, { value }) => {
    this.setState({ panels: value });
  };

  renderQtyDropDown = ({ placeholder }) => {
    const qtyOptions = [
      { key: "1", text: "1", value: "1" },
      { key: "2", text: "2", value: "2" },
      { key: "3", text: "3", value: "3" },
      { key: "4", text: "4", value: "4" },
      { key: "5", text: "5", value: "5" },
      { key: "6", text: "6", value: "6" },
      { key: "7", text: "7", value: "7" },
      { key: "8", text: "8", value: "8" },
      { key: "9", text: "9", value: "9" },
      { key: "10", text: "10", value: "10" },
    ];

    let style = null;
    if (isMobile) {
      style = {
        minWidth: "90%",
        marginBottom: "1em",
      };
    } else {
      style = {
        maxWidth: "50%",
        width: "50%",
        marginBottom: "1em",
      };
    }

    return (
      <Dropdown
        style={style}
        placeholder={placeholder}
        fluid
        selection
        options={qtyOptions}
        onChange={this.onQtyDropdownChange}
      />
    );
  };

  onSubmit = (formValues) => {
    this.props.createEstimateItem(
      formValues,
      this.props.selectedSealer.id,
      this.props.selectedSealer.name,
      this.state.panels,
      this.props.paintShade
    );
    //clear inputs
    this.props.dispatch(change("estimateItemCreate", "sealerlength", ""));
    this.props.dispatch(change("estimateItemCreate", "sealerwidth", ""));
    this.setState({ panels: null });
  };

  onFocus = (event, name) => {
    //attempt to clear sealerlength field. Not working - come back to this.
    this.props.dispatch(
      clearFields(event.target.form.name, true, true, ...[name, name])
    );
  };

  renderLabourRateInput() {
    if (this.props.calculationMethod === USE_LABOUR_RATE) {
      return (
        <Field
          name="labourrate"
          component={this.renderInput}
          id="labourInput"
          placeholder="Enter Labour Rate"
          label={this.props.currency.name}
        />
      );
    }
  }

  renderShadeDropdown() {
    const shadeOptions = [
      { key: "light", text: "Light", value: "light" },
      { key: "dark", text: "Dark", value: "dark" },
    ];

    let style = null;
    if (isMobile) {
      style = {
        width: "90%",
        marginBottom: "1em",
      };
    } else {
      style = {
        maxWidth: "25%",
        marginBottom: "1em",
      };
    }

    if (this.props.selectedSealer.id === "LSC") {
      return (
        <Field
          name="shadelight"
          component={this.renderDropDownLight}
          id="shadeSelectLight"
          value={this.props.selectedPaintShade}
          placeholder="Vehicle paint shade"
          width="100px"
          shadeOptions={shadeOptions}
          style={style}
        />
      );
    } else if (this.props.selectedSealer.id === "HSC") {
      return (
        <Field
          name="shadeheavy"
          component={this.renderDropDownHeavy}
          id="shadeSelectHeavy"
          value={this.props.selectedPaintShade}
          placeholder="Vehicle paint shade"
          width="100px"
          shadeOptions={shadeOptions}
          style={style}
        />
      );
    }
  }

  renderPanelQtyDropdown() {
    if (this.props.selectedSealer.calculationtype === "each") {
      return (
        <Field
          name="panels"
          component={this.renderQtyDropDown}
          id="panelqty"
          placeholder="Number of panels"
        />
      );
    }
  }

  determineAreaInputStyle = () => {
    if (
      this.props.calculationMethod === USE_LABOUR_RATE &&
      this.props.selectedSealer.sealerunitcostwhite
    ) {
      return "four fields";
    }
    if (
      this.props.calculationMethod === USE_LABOUR_RATE ||
      this.props.selectedSealer.sealerunitcostwhite
    ) {
      return "three fields";
    }
    return "two fields";
  };

  renderAreaInputs() {
    if (this.props.selectedSealer.calculationtype === "area") {
      return (
        <div className={this.determineAreaInputStyle()}>
          {this.renderShadeDropdown()}
          {this.renderLabourRateInput()}
          <Field
            name="sealerlength"
            id="lenInput"
            component={this.renderInput}
            placeholder="Length"
            label="mm"
            autoFocus
          />
          <Field
            name="sealerwidth"
            id="widthInput"
            component={this.renderInput}
            placeholder="Width"
            label="mm"
          />
        </div>
      );
    }
    return;
  }

  determineLengthInputStyle = () => {
    if (
      this.props.calculationMethod === USE_LABOUR_RATE &&
      this.props.selectedSealer.sealerunitcostwhite
    ) {
      return "three fields";
    }
    if (
      this.props.calculationMethod === USE_LABOUR_RATE ||
      this.props.selectedSealer.sealerunitcostwhite
    ) {
      return "two fields";
    }
    return "one field";
  };

  renderLengthInputs() {
    if (this.props.selectedSealer.calculationtype === "length") {
      return (
        <div className={this.determineLengthInputStyle()}>
          {this.renderShadeDropdown()}
          {this.renderLabourRateInput()}
          <Field
            name="sealerlength"
            id="lenInput"
            component={this.renderInput}
            placeholder="Length"
            label="mm"
            autoFocus
          />
        </div>
      );
    }
    return;
  }

  determinePanelQtyInputStyle = () => {
    if (this.props.calculationMethod === USE_LABOUR_RATE) {
      return "two fields";
    }
    return "one field";
  };

  renderPanelQtyInputs() {
    if (this.props.selectedSealer.calculationtype === "each") {
      return (
        <div className={this.determinePanelQtyInputStyle()}>
          {this.renderPanelQtyDropdown()}
          {this.renderLabourRateInput()}
        </div>
      );
    }
    return;
  }

  renderButtons() {
    return (
      <div style={{ textAlign: "right" }}>
        <button className="ui button primary">Add To Quote</button>
      </div>
    );
  }

  render() {
    return (
      <div className="margin-top-medium">
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          name="estimateForm"
          className="ui form error"
        >
          {this.renderAreaInputs()}
          {this.renderLengthInputs()}
          {this.renderPanelQtyInputs()}
          {this.renderButtons()}
        </form>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.sealerlength) {
    //keys of object must match field names
    errors.sealerlength = "You must enter a length";
  }
  if (!formValues.labourrate) {
    //keys of object must match field names
    errors.labourrate = "You must enter labour rate";
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "estimateItemCreate",
  validate: validate,
  enableReinitialize: true,
})(NewEstimateItemCreate);

const mapStateToProps = (state) => {
  return {
    selectedSealer: state.selectedSealer,
    selectedItem: state.estimates.selectedItem,
    formActions: state.formActions,
    currency: state.user.settings.setitems.currency
      ? state.user.settings.setitems.currency
      : { name: "ZAR" },
    email: state.auth.email,
    calculationMethod: state.user.settings.setitems.calculationMethod,
    paintShade: state.estimateFormActions.selectedPaintShade,
  };
};

export default connect(mapStateToProps, {
  createEstimateItem,
  getUserSettings,
  setPaintShade,
})(formWrapped);
