import React from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Form, Input, Button, Icon } from "semantic-ui-react";
import {
  busySaving,
  saveSuccess,
  saveFailed,
  resetSaveSuccess,
} from "../../actions";
import userPool from "./AWSUserPool";
import history from "../../history";

class AWSResetPassword extends React.Component {
  renderUpdateError(error) {
    if (error) {
      return <div className="ui error message">{error}</div>;
    }
    return "";
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderSuccessMessage() {
    if (this.props.passwordUpdateSuccessMessage) {
      return (
        <div className="ui postive message">
          <div className="header">
            {this.props.passwordUpdateSuccessMessage}
          </div>
        </div>
      );
    }
  }

  renderInput = ({ input, type, meta, placeholder, id, autoFocus, text }) => {
    return (
      <div>
        <Input
          type={type}
          fluid
          {...input}
          id={id}
          placeholder={placeholder}
          autoFocus={autoFocus}
          value={text}
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    //confirm password with code
    this.props.busySaving();
    const userData = {
      Username: this.props.userName,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(
      formValues.verificationCode,
      formValues.newPassword,
      {
        onSuccess: () => {
          this.props.saveSuccess("Password set successfully.");
          setTimeout(() => {
            this.props.resetSaveSuccess();
            history.push("/");
          }, 1500);
        },
        onFailure: (err) => {
          this.props.saveFailed(err);
        },
      }
    );
  };

  render() {
    return (
      <div>
        <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Form.Field>
            <Field
              name="email"
              type="text"
              component={this.renderInput}
              id="emailInput"
              placeholder="Email address"
              autoFocus
              text={this.props.userName}
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="verificationCode"
              type="text"
              component={this.renderInput}
              id="verificationCodeInput"
              placeholder="Verification Code"
              autoFocus
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="newPassword"
              type="password"
              component={this.renderInput}
              id="newpasswordInput"
              placeholder="New Password"
            />
          </Form.Field>
          <Button
            fluid
            loading={this.props.updatingPassword}
            primary
            icon
            labelPosition="right"
          >
            Reset Password
            <Icon name="lock open"></Icon>
          </Button>
        </Form>
        {this.renderSuccessMessage()}
        {this.renderUpdateError(this.props.passwordUpdateError)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    passwordUpdateError: state.api.saveError,
    updatingPassword: state.api.busySaving,
    userName: state.auth.passwordResetUsername,
    passwordUpdateSuccessMessage: state.api.successMessage,
  };
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.verificationCode) {
    //keys of object must match field names
    errors.verificationCode = "Please enter your verification code";
  }
  if (!formValues.newPassword) {
    //keys of object must match field names
    errors.newPassword = "Please enter your new password";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "AWSResetPassword",
  validate: validate,
})(AWSResetPassword);

export default connect(mapStateToProps, {
  busySaving,
  saveFailed,
  saveSuccess,
  resetSaveSuccess,
})(formWrapped);
