import React from "react";
import { Field, reduxForm } from "redux-form";
import { Form, Input, Button, Icon } from "semantic-ui-react";
import AWS from "aws-sdk";
import { connect } from "react-redux";
import {
  busySaving,
  saveFailed,
  saveSuccess,
  resetSaveSuccess,
} from "../../actions";
import { env } from "../../apis/env";
import history from "../../history";

class AWSConfirmEmail extends React.Component {
  renderConfirmError(error) {
    if (error) {
      return <div className="ui error message">{error}</div>;
    }
  }

  renderSuccessMessage(message) {
    if (message) {
      return <div className="ui positive message">{message}</div>;
    }
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, type, meta, placeholder, id, autoFocus }) => {
    return (
      <div>
        <Input
          type={type}
          fluid
          {...input}
          id={id}
          placeholder={placeholder}
          autoFocus={autoFocus}
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    const _env = env();
    this.props.busySaving();
    const savedEmail = localStorage.getItem("registerEmail");
    //if username not available from props (after page refresh) try and set to saved item
    //in local storage
    const userName = !this.props.userName ? savedEmail : this.props.userName;
    const params = {
      ClientId: _env.COGNITO_APP_CLIENT_ID,
      ConfirmationCode: formValues.verificationCode,
      Username: userName,
    };

    const cognitoAWSIdProvider = new AWS.CognitoIdentityServiceProvider({
      region: "eu-west-2",
    });
    cognitoAWSIdProvider.confirmSignUp(params, (err, data) => {
      if (err) {
        this.props.saveFailed(err.message);
      } else {
        this.props.saveSuccess(
          "Account successfully activated. You will be redirected momentarily..."
        );
        setTimeout(() => {
          history.push("/");
        }, 5000);
      }
    });
  };

  onInputChange = () => {
    this.props.resetSaveSuccess();
  };

  render() {
    return (
      <div>
        <Form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          name="AWSConfirmEmail"
          className="ui form error"
        >
          <Form.Field>
            <Field
              name="verificationCode"
              type="input"
              component={this.renderInput}
              id="verificationCodeInput"
              placeholder="Enter your Verification Code"
              autoFocus
              onChange={this.onInputChange}
            />
          </Form.Field>
          <Button
            fluid
            loading={this.props.confirmingEmail}
            primary
            icon
            labelPosition="right"
          >
            Activate Account
            <Icon name="check circle"></Icon>
          </Button>
          {this.renderConfirmError(this.props.confirmationError)}
          {this.renderSuccessMessage(this.props.successMessage)}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    confirmationError: state.api.saveError,
    confirmingEmail: state.api.busySaving,
    userName: state.auth.email,
    successMessage: state.api.successMessage,
  };
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.verificationCode) {
    //keys of object must match field names
    errors.verificationCode = "Please enter a verfication code";
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "AWSConfirmEmail",
  validate: validate,
})(AWSConfirmEmail);

export default connect(mapStateToProps, {
  busySaving,
  saveFailed,
  saveSuccess,
  resetSaveSuccess,
})(formWrapped);
