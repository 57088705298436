import React from "react";
import { Form, Grid, Radio } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  busySavingLabourUnits,
  resetSaveSuccessLabourUnitsPerHour,
} from "../../actions";

class SetLabourUnitsPerHour extends React.Component {
  renderIncompleteMessage() {
    if (!this.props.labourUnitsPerHour) {
      return (
        <div className="ui warning message">
          <i className="large red bell icon"></i>
          Please select
        </div>
      );
    }
  }

  renderSuccessMessage() {
    if (this.props.successMessage) {
      return (
        <div className="ui positive message">{this.props.successMessage}</div>
      );
    }
    return "";
  }

  renderErrorMessage() {
    if (this.props.errorMessage) {
      return (
        <div className="ui negative message">
          Could not update the currency. {this.props.errorMessage}
        </div>
      );
    }
    return "";
  }

  handleChange = (e, { value }) => {
    this.props.busySavingLabourUnits();
    this.props.updateLabourUnitsPerHour(value);
    setTimeout(() => {
      this.props.resetSaveSuccessLabourUnitsPerHour();
    }, 3000);
  };

  render() {
    return (
      <div>
        <Form>
          <Grid stackable columns="2">
            <Grid.Column>
              <Form.Field>
                <Radio
                  name="tenUnitsRadio"
                  value="10"
                  label="10 units"
                  onChange={(this, this.handleChange)}
                  checked={this.props.labourUnitsPerHour === "10"}
                ></Radio>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <Radio
                  name="twelveUnitsRadio"
                  value="12"
                  label="12 units"
                  onChange={(this, this.handleChange)}
                  checked={this.props.labourUnitsPerHour === "12"}
                ></Radio>
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form>
        {this.renderIncompleteMessage()}
        {this.renderSuccessMessage()}
        {this.renderErrorMessage()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    labourUnitsPerHour: state.user.settings.setitems.labourUnitsPerHour,
    successMessage: state.api.successMessageLabourUnits,
    errorMessage: state.api.saveErrorLabourUnits,
  };
};

export default connect(mapStateToProps, {
  busySavingLabourUnits,
  resetSaveSuccessLabourUnitsPerHour,
})(SetLabourUnitsPerHour);
