import React from "react";
import {
  emailEstimate,
  getEstimate,
  busyMailingEstimate,
  resetEstimateEmail,
} from "../../actions";
import { connect } from "react-redux";
import EmailEstimateModal from "./EmailEstimateModal";
import history from "../../history";

class EmailEstimate extends React.Component {
  componentDidMount() {
    this.props.getEstimate(this.props.match.params.id);
  }

  sendEmail = (recipients) => {
    this.props.busyMailingEstimate();
    this.props.emailEstimate(this.props.match.params.id, recipients);
  };

  cancelSend = () => {
    this.props.resetEstimateEmail();
    history.push("/dashboard");
  };

  render() {
    return (
      <EmailEstimateModal
        title={"Email Quote"}
        onSendClick={this.sendEmail}
        onCancelClick={this.cancelSend}
        onInputChange={this.props.resetEstimateEmail}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    estimate: state.estimates.estimate,
  };
};

export default connect(mapStateToProps, {
  emailEstimate,
  getEstimate,
  busyMailingEstimate,
  resetEstimateEmail,
})(EmailEstimate);
