import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Grid,
  Menu,
  Segment,
  Sidebar,
  Icon,
  Popup,
  Divider,
  Header,
} from "semantic-ui-react";
import { signIn, signOut, setUserFirstName, getUserSettings } from "../actions";
import SessionManager from "./session/SessionManager";
import { ESTIMATES, CREATE, SETTINGS } from "./menuItemTypes";
import history from "../history";
import "./components.css";
import { env } from "../apis/env";

class Mainmenu extends React.Component {
  state = { visible: false };

  componentDidMount() {
    if (!this.props.isSignedIn) {
      const authData = SessionManager.checkAuth();
      if (authData.email) {
        this.props.signIn(authData.userId, authData.email, authData.authorizor);
        this.props.setUserFirstName(authData.firstName);
      }
    }
    //this.props.getUserSettings(this.props.email);
  }

  renderUserPopUpMenu = () => {
    let authIcon = "";
    let iconColor = "";
    if (this.props.authorizor === "COGNITO") {
      authIcon = "amazon";
      iconColor = "orange";
    } else {
      authIcon = "google";
      iconColor = "red";
    }

    return (
      <Menu vertical className="pointer" style={{ textAlign: "center" }}>
        <Menu.Item style={{ fontSize: "0.7em", textAlign: "center" }} header>
          <Icon name={authIcon} color={iconColor} />
          {this.props.email}
        </Menu.Item>
        <Menu.Item name="signout" onClick={() => this.onSignOutClick()}>
          <Icon name="arrow alternate circle right" color="blue" />
          Sign Out
        </Menu.Item>
        <Menu.Item name="settings" onClick={() => history.push("/profile")}>
          <Icon name="cog" color="blue" />
          Profile Settings
        </Menu.Item>
      </Menu>
    );
  };

  signOut() {
    this.props.signOut();
    SessionManager.terminateSession();
    history.push("/");
  }

  onSignOutClick() {
    const _env = env();
    if (this.props.authorizor === "GAPI") {
      window.gapi.load("client:auth2", () => {
        window.gapi.client
          .init({
            clientId: _env.GGLIENT_ID,
            scope: _env.GCLIENT_SCOPE,
          })
          .then(() => {
            this.auth = window.gapi.auth2.getAuthInstance();
            this.auth.signOut().then(() => {
              this.signOut();
            });
          });
      });
    } else {
      this.signOut();
    }
  }

  //Menu items to be rendered for each route or "view"
  menuItems = {
    "My Quotes": [
      {
        name: CREATE,
        icon: "add square",
        to: "/create",
      },
      {
        name: SETTINGS,
        icon: "settings",
        to: "/profile",
      },
    ],
    "New Quote": [
      {
        name: ESTIMATES,
        icon: "list alternate outline",
        to: "/dashboard",
      },
      {
        name: SETTINGS,
        icon: "settings",
        to: "/profile",
      },
    ],
    "Quote Items": [
      {
        name: ESTIMATES,
        icon: "list alternate outline",
        to: "/dashboard",
      },
      {
        name: CREATE,
        icon: "add square",
        to: "/create",
      },
      {
        name: SETTINGS,
        icon: "settings",
        to: "/profile",
      },
    ],
    "Settings": [
      {
        name: ESTIMATES,
        icon: "list alternate outline",
        to: "/dashboard",
      },
      {
        name: CREATE,
        icon: "add square",
        to: "/create",
      },
    ],
  };

  constructMenuItems = (icon_size) => {
    const menu = this.menuItems[this.props.renderMenuFor].map((item) => {
      return (
        <Menu.Item
          as={Link}
          key={item.name}
          to={item.to}
          name={item.name}
          //only disable create item if user has not completed their profile with items
          //critical to estimation
          disabled={!this.props.profileIsComplete && item.name === CREATE}
        >
          <Icon
            color="orange"
            size={icon_size}
            name={item.icon}
            circular
            inverted
          />
          {item.name}
        </Menu.Item>
      );
    });

    return <>{menu}</>;
  };

  renderUserMenu = (icon_size) => {
    const popupstyle = {
      borderRadius: 10,
      padding: "0",
      borderStyle: "none",
      boxShadow: "none",
    };

    const nameTagStyle = {
      marginTop: "1em",
      marginRight: "0.5em",
      fontWeight: 300,
      color: "blue",
    };

    var itemStyle = null;
    if (icon_size === "huge") {
      itemStyle = {
        marginTop: "1em",
      };
    }
    return (
      <Menu.Menu position="right">
        <Menu.Item style={itemStyle}>
          <Header as="h5" style={nameTagStyle}>
            Logged in as {this.props.firstName}{" "}
          </Header>
          <Popup
            style={popupstyle}
            trigger={
              <Icon
                name="user"
                color="orange"
                size={icon_size}
                circular
                inverted
              />
            }
            on="hover"
            hoverable
            basic
            content={this.renderUserPopUpMenu()}
            position="bottom left"
          />
        </Menu.Item>
        <Divider></Divider>
      </Menu.Menu>
    );
  };

  renderMenu = (isSignedIn) => {
    if (isSignedIn) {
      return (
        <div>
          <>
            <Grid>
              <Grid.Row only="computer">
                <Grid.Column>
                  <Menu borderless secondary>
                    {this.constructMenuItems("large")}
                    {this.renderUserMenu("large")}
                  </Menu>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row only="mobile tablet">
                <Grid.Column>
                  <Menu>
                    <Menu.Item
                      icon="bars"
                      onClick={() =>
                        this.setState({ visible: !this.state.visible })
                      }
                    ></Menu.Item>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Sidebar.Pushable as={Segment} basic>
              <Sidebar
                as={Menu}
                animation="overlay"
                icon="labeled"
                vertical
                visible={this.state.visible}
              >
                {this.renderUserMenu("big")}
                {this.constructMenuItems("tiny")}
              </Sidebar>
              <Sidebar.Pusher>{this.props.contentView}</Sidebar.Pusher>
            </Sidebar.Pushable>
          </>
        </div>
      );
    } else {
      return (
        <div className="ui error message">
          You must be logged in to view this content
        </div>
      );
    }
  };

  render() {
    return this.renderMenu(this.props.isSignedIn);
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    authorizor: state.auth.authorizor,
    firstName: state.auth.firstName,
    email: state.auth.email,
    profileIsComplete: state.user.settings.complete,
  };
};

export default connect(mapStateToProps, {
  signIn,
  signOut,
  setUserFirstName,
  getUserSettings,
})(Mainmenu);
