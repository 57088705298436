import Axios from "axios";
import { env } from "./env";
import _ from "lodash";

export const Estimates = ({
  uname,
  limit,
  estimateId,
  estimatename,
  range,
  startId,
  startUser,
}) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;
  let rangeFilter = "";
  let startKeyFilter = "";
  if (uname) {
    if (estimatename) {
      return Axios.create({
        baseURL: `${baseURL}/estimates?uname=${uname}&name=${estimatename.toLowerCase()}`,
        headers: { "x-api-key": key },
      });
    } else {
      startKeyFilter = startId
        ? `&startid=${startId}&startuser=${startUser}`
        : "";
      rangeFilter = range ? `&${range}` : "";
      const lmt = limit ? "&limit=" + limit : "";
      return Axios.create({
        baseURL: `${baseURL}/estimates?uname=${uname}${rangeFilter}${startKeyFilter}${lmt}`,
        headers: { "x-api-key": key },
      });
    }
  } else {
    if (estimateId) {
      return Axios.create({
        baseURL: `${baseURL}/estimates/${estimateId}`,
        headers: { "x-api-key": key },
      });
    }
  }

  return Axios.create({
    baseURL: `${baseURL}/estimates`,
    headers: { "x-api-key": key },
  });
};

const compileEstimateData = (estimateData) => {
  return {
    id: estimateData.estimateId,
    estimatename: estimateData.saveAsName,
    s_estimatename: estimateData.saveAsName.toLowerCase(),
    insurername: estimateData.insurer ? estimateData.insurer : null,
    s_insurername: estimateData.insurer
      ? estimateData.insurer.toLowerCase()
      : null,
    regnumber: estimateData.registration ? estimateData.registration : null,
    s_regnumber: estimateData.registration
      ? estimateData.registration.toLowerCase()
      : null,
    username: estimateData.username,
    userid: estimateData.userid,
    itemcount: estimateData.itemcount,
    cost: estimateData.cost,
    workunitsperhour: estimateData.unitsPerHour,
    totalmaterial: estimateData.materialCost,
    totallabourcost: estimateData.labourCost,
    totallabourunits: estimateData.labourUnits,
  };
};

const calcLabourUnits = (labourMins, workunitsperhour) => {
  if (labourMins) {
    return parseFloat((labourMins / (60 / workunitsperhour)).toFixed(2));
  }
};

const compileItemData = (item, workunitsperhour) => {
  return {
    id: item.recordId,
    sealerid: item.sealerid,
    sealertype: item.name,
    length: item.sealerlength,
    itemcost: item.total,
    totalmaterial: item.totalMaterial,
    totallabourcost: item.totalLabourCost,
    totallabourunits: calcLabourUnits(item.totalLabourMins, workunitsperhour),
    totallabourmins: item.totalLabourMins,
    area: item.area,
    width: item.sealerwidth,
    panels: item.panels,
    shade: item.shade,
  };
};

export const compileDataForSave = (
  estimateId,
  saveAsName,
  insurer,
  registration,
  items,
  workunitsperhour,
  username,
  userid
) => {
  const newItems = [];
  const totals = {};
  let totalCost = 0;
  let totalMaterial = 0;
  let totalLabourCost = 0;
  let totalLabourUnits = 0;
  let totalLabourMins = 0;
  let cnt = 0;
  const estimatePropsArray = _.values(items);
  estimatePropsArray.forEach((item) => {
    //get estimate data, append the estimate name sent as parameter of type object and append the estimate ID object
    newItems.push(compileItemData(item, workunitsperhour));
    totalCost += item.total ? item.total : 0;
    totalMaterial += item.totalMaterial;
    totalLabourCost += item.totalLabourCost ? item.totalLabourCost : 0;
    totalLabourMins += item.totalLabourMins ? item.totalLabourMins : 0;
    totalLabourUnits = calcLabourUnits(totalLabourMins, workunitsperhour);
    cnt = cnt + 1;
  });
  totals.unitsPerHour = workunitsperhour;
  totals.cost = parseFloat(totalCost.toFixed(2));
  totals.materialCost = parseFloat(totalMaterial.toFixed(2));
  totals.labourCost = null;
  if (totalLabourCost > 0) {
    totals.labourCost = parseFloat(totalLabourCost.toFixed(2));
  }
  totals.labourUnits = null;
  if (totalLabourUnits > 0) {
    totals.labourUnits = totalLabourUnits;
  }
  totals.itemcount = cnt;

  const data = compileEstimateData({
    saveAsName,
    insurer,
    registration,
    estimateId,
    ...totals,
    username,
    userid,
  });
  data.items = newItems;
  return data;
};
