import React from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Form, Input, Button, Icon } from "semantic-ui-react";
import {
  busySaving,
  saveFailed,
  saveSuccess,
  resetSaveSuccess,
} from "../../actions";
import userPool from "./AWSUserPool";
import "../components.css";

class AWSUpdatePassword extends React.Component {
  componentDidMount() {
    this.props.resetSaveSuccess();
  }

  renderUpdateError(error) {
    if (error) {
      return <div className="ui error message">{error}</div>;
    }
  }

  renderSuccessMessage(message) {
    if (message) {
      return <div className="ui positive message">{message}</div>;
    }
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 4000);
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, type, meta, placeholder, id, autoFocus }) => {
    return (
      <div>
        <Input
          type={type}
          fluid
          {...input}
          id={id}
          placeholder={placeholder}
          autoFocus={autoFocus}
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.busySaving();
    const userData = {
      Username: this.props.userName,
      Pool: userPool,
    };

    const user = new CognitoUser(userData);

    const authDetails = new AuthenticationDetails({
      Username: this.props.Username,
      Password: formValues.currentPassword,
    });

    user.authenticateUser(authDetails, {
      onSuccess: () => {
        user.getSession((err, session) => {
          if (err) {
            this.props.saveFailed(err.message);
            console.log("Error", err.message);
            return;
          }
        });
        user.changePassword(
          formValues.currentPassword,
          formValues.newPassword,
          (err, result) => {
            if (err) {
              this.props.saveFailed(err.message);
              console.log("Error", err.message);
              return;
            } else {
              this.props.saveSuccess(result);
            }
          }
        );
      },
      onFailure: (err) => {
        this.props.saveFailed(err.message);
        console.log("Error", err.message);
      },
    });
  };

  onInputChange = () => {
    this.props.resetSaveSuccess();
  };

  render() {
    return (
      <div>
        <Form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          name="AWSUpdatePassword"
          className="ui form error"
        >
          <Form.Field>
            <Field
              name="currentPassword"
              type="password"
              component={this.renderInput}
              id="currentpasswordInput"
              placeholder="Current Password"
              autoFocus
              onChange={this.onInputChange}
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="newPassword"
              type="password"
              component={this.renderInput}
              id="newpasswordInput"
              placeholder="New Password"
              onChange={this.onInputChange}
            />
          </Form.Field>
          <Button
            fluid
            loading={this.props.updatingPassword}
            primary
            icon
            labelPosition="right"
          >
            Update Password
            <Icon name="sign in"></Icon>
          </Button>
          {this.renderUpdateError(this.props.passwordUpdateError)}
          {this.renderSuccessMessage(this.props.successMessage)}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    passwordUpdateError: state.api.saveError,
    updatingPassword: state.api.busySaving,
    userName: state.auth.email,
    successMessage: state.api.successMessage,
  };
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.newPassword) {
    //keys of object must match field names
    errors.newPassword = "Please enter your new password";
  }
  if (!formValues.currentPassword) {
    //keys of object must match field names
    errors.currentPassword = "Please enter your current password";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "AWSUpdatePassword",
  validate: validate,
})(AWSUpdatePassword);

export default connect(mapStateToProps, {
  busySaving,
  saveFailed,
  saveSuccess,
  resetSaveSuccess,
})(formWrapped);
