import React from "react";
import { connect } from "react-redux";
import {
  getEstimates,
  selectEstimate,
  setEstimateSearchRange,
  clearEstimate,
  getUserSettings,
  loadEstimateForEdit,
  getSealers,
} from "../../actions";
import "../components.css";
import EstimateList from "./EstimateList";
import history from "../../history";
import utils from "../../utilities";
import SearchBar from "./Searchbar";
import ExportToCsv from "./ExportToCsv";
import WorksheetPDF from "./worksheetPDF";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import { PDF, DELETE, EMAIL, EDIT } from "../../actions/types";
import EstimatePdf from "./EstimatePdf";
import { Link } from "react-router-dom";
import { env } from "../../apis/env";
import { isMobile } from "react-device-detect";

class Dashboard extends React.Component {
  //get record size variable from environment variables
  _env = env();

  //local state just to manage the number of estimate records loaded per page
  state = { resultsPerPage: this._env.RECORD_SET_SIZE };

  componentDidMount() {
    this.props.getEstimates({
      uname: this.props.email,
      limit: this.state.resultsPerPage,
    });
    //get user settings to check if profile set up
    this.props.getUserSettings(this.props.email);
  }

  onEstimateClick = (estimateData) => {
    this.props.clearEstimate();
    this.props.selectEstimate(estimateData);
    history.push(`/estimates/${estimateData.estimateId}`);
  };

  onItemActionSelect = (action, estimate) => {
    switch (action) {
      case DELETE:
        history.push(`/archive/${estimate.id}`);
        break;
      case PDF:
        const pdf = new EstimatePdf();
        pdf.download(estimate);
        break;
      case EMAIL:
        history.push(`/share/${estimate.id}`);
        break;
      case EDIT:
        history.push(`/update/${estimate.id}`);
        break;
      default:
        return;
    }
  };

  renderList() {
    if (this.props.estimates.length > 0) {
      const u = new utils();
      return this.props.estimates.map((estimate) => {
        return (
          <EstimateList
            estimate={estimate}
            key={estimate.id}
            onActionSelect={this.onItemActionSelect}
            onEstimateClick={this.onEstimateClick}
            itemNumber={u.nextNumber()}
          />
        );
      });
    } else {
      return (
        <div style={{ textAlign: "center" }} className="ui positive message">
          <i className="big edit bell icon"></i>
          No saved estimates...
        </div>
      );
    }
  }

  setResultsPerPage = (event, data) => {
    this.setState({ resultsPerPage: data.value }, () => {
      this.onDropdownSelect();
    });
  };

  onLoadMoreClick = () => {
    let startKey = null;
    if (this.props.lastEstimateKey) {
      startKey = {
        startId: this.props.lastEstimateKey.id,
        startUser: this.props.lastEstimateKey.username,
      };
    }
    const params = {
      uname: this.props.email,
      limit: this.state.resultsPerPage,
      ...startKey,
    };
    this.props.getEstimates(params);
  };

  renderResultsPerPageDropdown() {
    const resultsPerPageOptions = [
      {
        key: 5,
        text: "5 per page",
        value: 5,
      },
      {
        key: 10,
        text: "10 per page",
        value: 10,
      },
      {
        key: 25,
        text: "25 per page",
        value: 25,
      },
      {
        key: 50,
        text: "50 per page",
        value: 50,
      },
      {
        key: 100,
        text: "100 per page",
        value: 100,
      },
    ];
    return (
      <span
        style={{ maxWidth: "50%" }}
        className="margin-bottom-large margin-top-medium"
      >
        <Dropdown
          compact
          placeholder="rows per page"
          selection
          options={resultsPerPageOptions}
          onChange={this.setResultsPerPage}
        ></Dropdown>
      </span>
    );
  }

  renderLoadMoreButton() {
    return (
      <span
        style={{ maxWidth: "50%" }}
        className="margin-bottom-large margin-top-medium"
      >
        <Button
          className="ui basic right floated button"
          icon
          labelPosition="right"
          onClick={this.onLoadMoreClick}
        >
          {this.props.lastEstimateKey ? "Next..." : "To Page 1..."}
          <Icon name="right arrow" />
        </Button>
      </span>
    );
  }

  onDropdownSelect = (range) => {
    this.props.setEstimateSearchRange(range);
    this.props.getEstimates({
      uname: this.props.email,
      limit: this.state.resultsPerPage,
      range: range,
    });
  };

  onSearchTermChange = (term, range) => {
    this.props.getEstimates({
      uname: this.props.email,
      limit: this.state.resultsPerPage,
      range: range,
      estimatename: term,
    });
  };

  renderCompleteSettingsBanner = () => {
    if (
      !this.props.labourUnitsPerHour ||
      !this.props.userCurrency ||
      !this.props.calculationMethod
    ) {
      return (
        <div
          style={{ textAlign: "center", marginBottom: "2em" }}
          className="ui warning message"
        >
          <i className="large red bell icon"></i>
          Please complete your user profile settings
          <Link to="/profile"> here</Link>. Incomplete settings may lead to
          unexpected calculation results.
        </div>
      );
    }
  };

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    const menuColsWidthMobile = `${isMobile ? "three" : "seven"} wide column`;
    const headColWidth = `${isMobile ? "eight" : "eight"} wide column`;
    return (
      <>
        <div className={className}>
          {this.renderCompleteSettingsBanner()}
          <div className="ui two column grid">
            <div className={headColWidth}>
              <h3 style={{ marginBottom: "1.5em" }}>My Quotes</h3>
            </div>
            <div
              className={menuColsWidthMobile}
              style={{ marginBottom: "1.5m", textAlign: "right" }}
            >
              <WorksheetPDF />
            </div>
            <div
              className="one wide column"
              style={{ marginBottom: "1.5m", textAlign: "right" }}
            >
              <ExportToCsv Estimates={this.props.estimates} />
            </div>
          </div>
          <div className="ui  two column grid">
            <SearchBar
              onSearchTermChange={this.onSearchTermChange}
              onDropdownSelect={this.onDropdownSelect}
            ></SearchBar>
          </div>
          {this.renderList()}
          <div className="ui stackable two column grid">
            <div className="sixteen wide column">
              {this.renderResultsPerPageDropdown()}
              {this.renderLoadMoreButton()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    estimates: state.estimates.estimates,
    sealers: state.sealers,
    userId: state.auth.userId,
    email: state.auth.email,
    searchRange: state.estimates.searchRange,
    lastEstimateKey: state.estimates.lastEstimateKey,
    calculationMethod: state.user.settings.setitems.calculationMethod,
    userCurrency: state.user.settings.setitems.currency,
    labourUnitsPerHour: state.user.settings.setitems.labourUnitsPerHour,
  };
};

export default connect(mapStateToProps, {
  getEstimates,
  selectEstimate,
  setEstimateSearchRange,
  clearEstimate,
  getUserSettings,
  loadEstimateForEdit,
  getSealers,
})(Dashboard);
