import React from "react";
import AWSUpdatePassword from "../authentication/AWSUpdatePassword";
import SetCurrency from "../userProfile/SetCurrency";
import { connect } from "react-redux";
import {
  getUserSettings,
  updateUserSettings,
  createUserSettings,
  saveCalcMethodSuccess,
  saveFailedCalcMethod,
  saveFailedLabourUnitsPerHour,
  saveLabourUnitsPerHourSuccess,
  saveCurrencySuccess,
  saveFailedCurrency,
} from "../../actions";
import "../components.css";
import { Grid, Segment } from "semantic-ui-react";
import { profileSettingTypes, currencyUnits } from "./profileSettingTypes";
import SetCalculationMethod from "../userProfile/SetCalculationMethod";
import SetLabourUnitsPerHour from "./SetLabourUnitsPerHour";

class UpdateProfile extends React.Component {
  componentDidMount() {
    this.props.getUserSettings(this.props.email);
  }

  isProfileComplete = () => {
    return (
      this.props.calculationMethod !== null &&
      this.props.userSettingsID !== null &&
      this.props.labourUnitsPerHour !== null
    );
  };

  updateCurrency = (currency) => {
    if (this.props.userSettingsID) {
      try {
        this.props.updateUserSettings(
          this.compileSettingsData({ currency: currency }),
          profileSettingTypes.CURRENCY
        );
      } catch (e) {
        this.props.saveFailedCurrency(e.message);
        return;
      }
      this.props.saveCurrencySuccess();
    } else {
      try {
        this.props.createUserSettings(
          this.compileSettingsData({ currency: currency })
        );
      } catch (e) {
        this.props.saveFailedCurrency(e.message);
        return;
      }
      this.props.saveCurrencySuccess();
    }
  };

  updateCalculationMethod = (calculationMethod) => {
    if (this.props.userSettingsID) {
      try {
        this.props.updateUserSettings(
          this.compileSettingsData({ calculationMethod: calculationMethod }),
          profileSettingTypes.CALCULATION_METHOD
        );
      } catch (e) {
        this.props.saveFailedCalcMethod(e.message);
        return;
      }
      this.props.saveCalcMethodSuccess();
    } else {
      try {
        this.props.createUserSettings(
          this.compileSettingsData({ calculationMethod: calculationMethod })
        );
      } catch (e) {
        this.props.saveFailedCalcMethod(e.message);
        return;
      }
      this.props.saveCalcMethodSuccess();
    }
  };

  updateLabourUnitsPerHour = (labourUnitsPerHour) => {
    if (this.props.userSettingsID) {
      try {
        this.props.updateUserSettings(
          this.compileSettingsData({ labourUnitsPerHour: labourUnitsPerHour }),
          profileSettingTypes.WORK_UNITS_PER_HOUR
        );
      } catch (e) {
        this.props.saveFailedLabourUnitsPerHour(e.message);
        return;
      }
      this.props.saveLabourUnitsPerHourSuccess();
    } else {
      try {
        this.props.createUserSettings(
          this.compileSettingsData({ labourUnitsPerHour: labourUnitsPerHour })
        );
      } catch (e) {
        this.props.saveFailedLabourUnitsPerHour(e.message);
      }
      this.props.saveLabourUnitsPerHourSuccess();
    }
  };

  compileSettingsData = ({
    currency,
    calculationMethod,
    labourUnitsPerHour,
  }) => {
    return {
      email: this.props.email,
      complete: this.isProfileComplete(),
      setitems: {
        currency: currency
          ? { name: currency, unit: currencyUnits[currency] }
          : {
              name: this.props.currency.name,
              unit: currencyUnits[this.props.currency.name],
            },
        calculationMethod: calculationMethod
          ? calculationMethod
          : this.props.calculationMethod,
        labourUnitsPerHour: labourUnitsPerHour
          ? labourUnitsPerHour
          : this.props.labourUnitsPerHour,
      },
    };
  };

  renderUpdatePassword = () => {
    if (this.props.authorizor === "COGNITO") {
      return (
        <Segment padded="very">
          <h3>Update Password</h3>
          <AWSUpdatePassword />
        </Segment>
      );
    }
  };

  render() {
    return (
      <div>
        <Grid stackable textAlign="center">
          <Grid.Column width="eight">
            <Segment padded="very">
              <h3>Preferred Currency</h3>
              <SetCurrency updateCurrency={this.updateCurrency} />
            </Segment>
            <Segment padded="very">
              <h3>Calculation Method</h3>
              <SetCalculationMethod
                updateCalculationMethod={this.updateCalculationMethod}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width="eight">
            <Segment padded="very">
              <h3>Labour Units Per Hour</h3>
              <SetLabourUnitsPerHour
                updateLabourUnitsPerHour={this.updateLabourUnitsPerHour}
              />
            </Segment>
            {this.renderUpdatePassword()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    currency: state.user.settings.setitems.currency,
    calculationMethod: state.user.settings.setitems.calculationMethod,
    userSettingsID: state.user.settings.email,
    labourUnitsPerHour: state.user.settings.setitems.labourUnitsPerHour,
    authorizor: state.auth.authorizor,
  };
};

export default connect(mapStateToProps, {
  getUserSettings,
  updateUserSettings,
  createUserSettings,
  saveFailedCalcMethod,
  saveCalcMethodSuccess,
  saveFailedLabourUnitsPerHour,
  saveLabourUnitsPerHourSuccess,
  saveCurrencySuccess,
  saveFailedCurrency,
})(UpdateProfile);
