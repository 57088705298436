import React from "react";
import jsPDF from "jspdf";
import utils from "../../utilities";
import pdfHelper from "../../pdfHelper";
import _cralogo from "../../assets/cra.jpg";
import _sambralogo from "../../assets/sambra.jpg";

class EstimatePdf extends React.Component {
  constructor(props) {
    super(props);
    this.pdf = new pdfHelper(props, {
      h1Color: "blue",
      textLineHeight: 2.5,
      yStart: 50,
      h2Color: "navy",
      bottomMargin: 30,
    });
  }

  renderWorkUnitsPerHour = (estimate) => {
    if (estimate.totallabourunits) {
      return `Labour Units Per Hour: ${estimate.workunitsperhour}`;
    }
  };

  renderEstimateLabour = (estimate) => {
    if (estimate.totallabourunits) {
      return ` ${estimate.totallabourunits.toFixed(2)} units`;
    } else {
      return ` ${estimate.totallabourcost.toFixed(2)}`;
    }
  };

  renderEstimateTotalCost = (estimate) => {
    if (estimate.cost) {
      return ` Total Cost: ${estimate.cost}`;
    }
  };

  download = (estimate) => {
    const u = new utils();
    const renderCreateDate = () => {
      return u.dateToLongString(new Date(estimate.createdat));
    };

    const renderItems = (doc) => {
      let itemFields = [];
      estimate.items.forEach((item) => {
        let lenOrArea = "";
        if (item.area) {
          lenOrArea = `Area: ${item.area.toFixed(2)} sq dm`;
        } else if (item.length) {
          lenOrArea = `Length: ${item.length} mm`;
        } else {
          lenOrArea = `Number of panels: ${item.panels}`;
        }
        itemFields.push(
          `Item ${u.nextNumber()}: ${
            item.sealertype
          }, ${lenOrArea}, Material: ${item.totalmaterial.toFixed(
            2
          )}, Labour: ${
            item.totallabourunits
              ? item.totallabourunits.toFixed(2) + " units"
              : item.totallabourcost.toFixed(2)
          } ${item.itemcost ? `, Total : ${item.itemcost.toFixed(2)}` : ""}`
        );
      });
      itemFields.forEach((item) => {
        return this.pdf.text(doc, item, 2);
      });
    };

    var doc = new jsPDF({
      orientation: "l",
      unit: "pt",
      format: "a4",
    });

    this.pdf.image(doc, _sambralogo, 2, 100, 50, false);
    this.pdf.border(doc);
    this.pdf.text(
      doc,
      "Automotive Sealer Calculation Quote",
      220,
      true,
      "h1",
      -20
    );
    this.pdf.image(doc, _cralogo, 650, 100, 50, true);
    this.pdf.line(doc, 1, 1, "gray");
    this.pdf.text(doc, "Description:", 1, false);
    this.pdf.text(doc, estimate.estimatename, 3, true);
    this.pdf.text(doc, "Vehicle Registration:", 1, false);
    this.pdf.text(doc, estimate.regnumber ? estimate.regnumber : "", 3, true);
    this.pdf.text(doc, "Insurer:", 1, false);
    this.pdf.text(
      doc,
      estimate.insurername ? estimate.insurername : "",
      3,
      true
    );
    this.pdf.text(doc, "Created:", 1, false);
    this.pdf.text(doc, renderCreateDate(), 3, true);
    this.pdf.text(doc, "Create User:", 1, false);
    this.pdf.text(doc, estimate.username, 3, true);
    if (estimate.totallabourunits) {
      this.pdf.text(doc, "Labour Units Per Hour:", 1, false);
      this.pdf.text(doc, estimate.workunitsperhour, 3, true);
    }
    this.pdf.text(doc, "Labour:", 1, false);
    this.pdf.text(doc, this.renderEstimateLabour(estimate), 3, true);
    this.pdf.text(doc, "Material:", 1, false);
    this.pdf.text(doc, estimate.totalmaterial.toString(), 3, true);
    if (estimate.cost) {
      this.pdf.text(doc, "Total Cost:", 1, false);
      this.pdf.text(doc, estimate.cost.toString(), 3, true);
    }
    this.pdf.text(doc, "Number of items:", 1, false);
    this.pdf.text(doc, estimate.itemcount.toString(), 3, true);
    this.pdf.line(doc, 1, 1, "gray");
    renderItems(doc);
    doc.save("SealerQuote.pdf");
  };
}

export default EstimatePdf;
