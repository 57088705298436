import { GET_SEALERS } from "../actions/types";

const sealersReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SEALERS:
      return action.payload;
    default:
      return state;
  }
};

export default sealersReducer;
