export const profileSettingTypes = {
  CALCULATION_METHOD: "CALCULATION_METHOD",
  CURRENCY: "CURRENCY",
  WORK_UNITS_PER_HOUR: "WORK_UNITS_PER_HOUR",
};
export const currencyUnits = {
  ZAR: "R",
  USD: "$",
  EUR: "€",
  GBP: "£",
  AUD: "$",
};

export const USE_LABOUR_RATE = "LR";
export const USE_WORK_UNITS = "WU";
