import React from "react";
import { connect } from "react-redux";
import { getEstimates } from "../../actions";
import CsvDownloader from "react-csv-downloader";
import { Button, Icon, Popup } from "semantic-ui-react";
import _ from "lodash";
import utils from "../../utilities";

class ExportToCsv extends React.Component {


  execute() {
    
  }


  render() {
    const u = new utils();
    let estimateItemsFlat = _.flatMap(this.props.Estimates, (estimate) => {
      return _.map(estimate.items, (item) => {
        return {
          estimatename: estimate.estimatename,
          insurername: estimate.insurername,
          regnumber: estimate.regnumber,
          createdat: u.dateToDateTimeString(new Date(estimate.createdat)),
          totallabourcost: estimate.totallabourcost,
          totallabourunits: estimate.totallabourunits,
          workunitsperhour: estimate.workunitsperhour,
          totalmaterial: estimate.totalmaterial,
          totalcost: estimate.cost,
          itemcount: estimate.itemcount,
          sealertype: item.sealertype,
          itemarea: item.area,
          itemlength: item.length,
          itemwidth: item.width,
          itemlabourcost: item.totallabourcost,
          itemlabourunits: item.totallabourunits,
          itemmaterial: item.totalmaterial,
          itemcost: item.itemcost,
          itempanels: item.panels,
        };
      });
    });

    const columns = [
      {
        id: "estimatename",
        displayName: "Name",
      },
      {
        id: "createdat",
        displayName: "Create Date",
      },
      {
        id: "itemcount",
        displayName: "# Items",
      },
      {
        id: "insurername",
        displayName: "Insurer Name",
      },
      {
        id: "regnumber",
        displayName: "Vehicle Registration",
      },
      {
        id: "totallabourcost",
        displayName: "Total Labour Cost",
      },
      {
        id: "totallabourunits",
        displayName: "Total Labour Units",
      },
      {
        id: "workunitsperhour",
        displayName: "Work Units Per Hour",
      },
      {
        id: "totalmaterial",
        displayName: "Total Material Cost",
      },
      {
        id: "totalcost",
        displayName: "Total Cost",
      },

      {
        id: "sealertype",
        displayName: "Sealer Type",
      },
      {
        id: "itemarea",
        displayName: "Area",
      },
      {
        id: "itemlength",
        displayName: "Length",
      },
      {
        id: "itemwidth",
        displayName: "Width",
      },
      {
        id: "itempanels",
        displayName: "Number of panels",
      },
      {
        id: "itemlabourcost",
        displayName: "Labour Cost",
      },
      {
        id: "itemlabourunits",
        displayName: "Labour Units",
      },
      {
        id: "itemmaterial",
        displayName: "Material Cost",
      },
      {
        id: "itemcost",
        displayName: "Item Total Cost",
      },
    ];

    return (
      <>
        <CsvDownloader
          datas={estimateItemsFlat}
          extension=".csv"
          separator=","
          columns={columns}
          filename="Quotes"
          suffix={true}
        >
          <Popup
            trigger={
              <Button icon circular compact basic>
                <Icon size="large" name="file excel" color="green"></Icon>
              </Button>
            }
            content="Export Quotes to CSV"
            position="left center"
          />
        </CsvDownloader>
      </>
    );
  }
}

export default connect(null, { getEstimates })(ExportToCsv);
