import React from "react";
import { connect } from "react-redux";
import "../components.css";
import {
  deleteEstimateItem,
  getUserSettings,
  getEstimate,
  loadEstimateForEdit,
  selectSealer,
  selectEstimateItem,
} from "../../actions";
import { Button } from "semantic-ui-react";
import utils from "../../utilities";
import { Link } from "react-router-dom";
import NewEstimateItemDetail from "./NewEstimateItemDetail";
import { USE_LABOUR_RATE } from "../userProfile/profileSettingTypes";

class NewEstimateItemList extends React.Component {
  componentDidMount() {
    if (this.props.isUpdate) {
      this.props.loadEstimateForEdit(this.props.estimateId);
    }
    this.props.getUserSettings(this.props.email);
  }

  deleteItem = (id) => {
    this.props.deleteEstimateItem(id);
  };

  selectItem = (selectedItem) => {
    this.props.selectSealer(
      this.props.sealers.find((sealer) => sealer.id === selectedItem.sealerid)
    );
    this.props.selectEstimateItem(selectedItem);
  };

  renderTotal() {
    if (this.props.newEstimateItems.length > 0) {
      let totalAmount = 0.0;
      this.props.newEstimateItems.forEach((item) => {
        totalAmount += parseFloat(item.total);
      });
      if (totalAmount > 0) {
        return (
          `Estimate Total : ${this.props.currency.unit} ` +
          totalAmount.toFixed(2)
        );
      } else {
        return;
      }
    } else return;
  }

  renderMaterialTotal() {
    if (this.props.newEstimateItems.length > 0) {
      let materialTotal = 0.0;
      this.props.newEstimateItems.forEach((estimateItem) => {
        materialTotal += parseFloat(estimateItem.totalMaterial);
      });
      if (materialTotal > 0) {
        return (
          `Materials Total : ${this.props.currency.unit} ` +
          materialTotal.toFixed(2)
        );
      } else {
        return;
      }
    } else return;
  }

  renderLabourTotal() {
    if (this.props.newEstimateItems.length > 0) {
      let labourTotal = 0.0;
      let units = "";
      this.props.newEstimateItems.forEach((estimateItem) => {
        if (estimateItem.totalLabourCost) {
          labourTotal += parseFloat(estimateItem.totalLabourCost);
        } else {
          labourTotal +=
            parseFloat(estimateItem.totalLabourMins) /
            (60 / parseFloat(this.props.workUnitsPerHour));
          units = " units";
        }
      });
      if (labourTotal > 0) {
        return (
          `Labour Total : ${
            this.props.calculationMethod === USE_LABOUR_RATE
              ? this.props.currency.unit
              : ""
          } ` +
          labourTotal.toFixed(2) +
          units
        );
      } else {
        return;
      }
    } else return;
  }

  renderlist() {
    if (!this.props.newEstimateItems.length > 0) {
      return <div style={{ textAlign: "center" }}>No items added</div>;
    }

    const u = new utils();

    return this.props.newEstimateItems.map((estimateItem) => {
      const itmNo = u.nextNumber();
      return (
        <NewEstimateItemDetail
          estimateItem={estimateItem}
          workUnitsPerHour={this.props.workUnitsPerHour}
          onDeleteClick={this.deleteItem}
          onItemSelect={this.selectItem}
          itemNumber={itmNo}
          key={itmNo}
          estimateId={this.props.estimateId}
          isUpdate={this.props.isUpdate}
          hideDeleteButton={this.props.newEstimateItems.length <= 1}
        />
      );
    });
  }

  renderSaveNewButton() {
    if (!this.props.isUpdate) {
      if (this.props.newEstimateItems.length > 0) {
        return (
          <div style={{ textAlign: "right" }}>
            <Link className="ui button primary" to="/save">
              Save
            </Link>
          </div>
        );
      }
    }
  }

  renderSaveUpdateButton() {
    if (this.props.isUpdate) {
      if (this.props.newEstimateItems.length > 0) {
        return (
          <div style={{ textAlign: "right" }}>
            <Button
              loading={this.props.busySaving}
              primary
              onClick={this.props.onUpdateClick}
            >
              Save Changes
            </Button>
          </div>
        );
      }
    }
  }

  renderUpdateResult = () => {
    if (this.props.errResult) {
      return (
        <div
          className="ui error message"
          style={{ marginBottom: "2em", marginTop: "2em" }}
        >
          {this.props.errResult}
        </div>
      );
    } else if (this.props.saveSuccess) {
      return (
        <div
          className="ui success message"
          style={{ marginBottom: "2em", marginTop: "2em" }}
        >
          Changes saved.
        </div>
      );
    }
  };

  render() {
    const heading = this.props.isUpdate
      ? `Edit Items for ${this.props.estimate.estimatename}`
      : "New Quote Items";
    return (
      <div className="ui very padded segment">
        <h3>{heading}</h3>
        <div className="ui very relaxed divided items">{this.renderlist()}</div>
        <div>{this.renderUpdateResult()}</div>
        <div className="ui stackable two column grid">
          <div className="column">
            <h4>{this.renderMaterialTotal()}</h4>
            <h4>{this.renderLabourTotal()}</h4>
            <h4>{this.renderTotal()}</h4>
          </div>
          <div className="column">
            {this.renderSaveNewButton()}
            {this.renderSaveUpdateButton()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    estimate: state.estimates.estimate,
    sealers: state.sealers,
    newEstimateItems: Object.values(state.newEstimateItems),
    email: state.auth.email,
    workUnitsPerHour: state.user.settings.setitems.labourUnitsPerHour,
    currency: state.user.settings.setitems.currency,
    calculationMethod: state.user.settings.setitems.calculationMethod,
    saveSuccess: state.api.saveSuccess,
    errResult: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

export default connect(mapStateToProps, {
  loadEstimateForEdit,
  deleteEstimateItem,
  getUserSettings,
  getEstimate,
  selectSealer,
  selectEstimateItem,
})(NewEstimateItemList);
