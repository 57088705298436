export const GET_SEALERS = "GET_SEALERS";
export const GET_SEALER = "GET_SEALER";
export const SELECT_SEALER = "SELECT_SEALER";
export const SELECT_ESTIMATE_ITEM = "SELECT_ESTIMATE_ITEM";
export const CREATE_ESTIMATE_ITEM = "CREATE_ESTIMATE_ITEM";
export const UPDATE_ESTIMATE_ITEM = "UPDATE_ESTIMATE_ITEM";
export const LOAD_ESTIMATE_ITEM_FOR_EDIT = "LOAD_ESTIMATE_ITEM_FOR_EDIT";
export const CLEAR_ESTIMATE = "CLEAR ESTIMATE";
export const SET_ESTIMATE_SEARCH_RANGE = "SET_ESTIMATE_SEARCH_RANGE";
export const SET_LAST_ESTIMATE_KEY = "SET_LAST_ESTIMATE_KEY";
export const SELECT_ESTIMATE = "SELECT_ESTIMATE";
export const GET_ESTIMATES = "GET_ESTIMATES";
export const GET_ESTIMATE = "GET_ESTIMATE";
export const ARCHIVE_ESTIMATE = "ARCHIVE_ESTIMATE";
export const BUSY_MAILING = "BUSY_MAILING";
export const MAILED_OK = "MAILED_OK";
export const MAIL_FAILED = "MAIL_FAILED";
export const CANCEL_MAIL_ESTIMATE = "CANCEL_MAIL_ESTIMATE";
export const DELETE_ESTIMATE_ITEM = "DELETE_ESTIMATE_ITEM";
export const GET_ESTIMATE_ITEM = "GET_ESTIMATE_ITEM";
export const BUSY_SAVING = "BUSY_SAVING";
export const SAVED_RECORD = "SAVED_RECORD";
export const SAVE_FAILED = "SAVE_FAILED";
export const SAVED_CALC_METHOD = "SAVED_CALC_METHOD";
export const BUSY_SAVING_CALC_METHOD = "BUSY_SAVING_CALC_METHOD";
export const SAVE_FAILED_CALC_METHOD = "SAVE_FAILED_CALC_METHOD";
export const CANCEL_SAVE_CALC_METHOD = "CANCEL_SAVE_CALC_METHOD";
export const SAVED_LABOUR_UNITS_PER_HOUR = "SAVED_LABOUR_UNITS_PER_HOUR";
export const BUSY_SAVING_LABOUR_UNITS_PER_HOUR =
  "BUSY_SAVING_LABOUR_UNITS_PER_HOUR";
export const SAVE_FAILED_LABOUR_UNITS_PER_HOUR =
  "SAVE_FAILED_LABOUR_UNITS_PER_HOUR";
export const CANCEL_SAVE_LABOUR_UNITS_PER_HOUR =
  "CANCEL_SAVE_LABOUR_UNITS_PER_HOUR";
export const SAVED_CURRENCY = "SAVED_CURRENCY";
export const BUSY_SAVING_CURRENCY = "BUSY_SAVING_CURRENCY";
export const SAVE_FAILED_CURRENCY = "SAVE_FAILED_CURRENCY";
export const CANCEL_SAVE_CURRENCY = "CANCEL_SAVE_CURRENCY";
export const CLEAR_NEW_ESTIMATE_ITEMS = "CLEAR_NEW_ESTIMATE_ITEMS";
export const CANCEL_SAVE = "CANCEL_SAVE";
export const SHOW_SEALER_NOT_SELECTED_ERROR = "SHOW_SEALER_NOT_SELECTED_ERROR";
export const HIDE_SEALER_NOT_SELECTED_ERROR = "HIDE_SEALER_NOT_SELECTED_ERROR";
export const SET_SELECTED_SHADE = "SET_SELECTED_SHADE";
export const CLEAR_SELECTED_SHADE = "CLEAR_SELECTED_SHADE";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_UP = "SIGN_UP";
export const SIGNED_UP = "SIGNED_UP";
export const SET_USER_FIRSTNAME = "SET_USER_FIRSTNAME";
export const FAIL_SIGNUP = "FAIL_SIGNUP";
export const FAIL_SIGN_IN = "FAIL_SIGN_IN";
export const PASSWORD_RESET_REQUEST_SUCCESS = "PASSWORD_RESET_REQUEST_SUCCESS";
export const SET_RESET_USERNAME = "SET_RESET_USERNAME";
export const RESET_SIGN_IN = "RESET_SIGN_IN";
export const DELETE = "DELETE";
export const PDF = "PDF";
export const WORKSHEET = "WORKSHEET";
export const EMAIL = "EMAIL";
export const EDIT = "EDIT";
export const GET_USER_SETTINGS = "GET_USER_SETTINGS";
export const CREATE_USER_SETTINGS = "CREATE_USER_SETTINGS";
