import React from "react";
import AWSSignUp from "./AWSSignUp";
import { Segment, Grid, Header, Icon } from "semantic-ui-react";

class SignUp extends React.Component {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Grid stackable textAlign="center">
          <Grid.Column width="eight">
            <Segment padded="very">
              <Header icon>
                <Icon name="user plus" />
                Sign Up
              </Header>
              <AWSSignUp />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default SignUp;
